import React, { useState, useEffect } from 'react';
import { coursePageAnswerSecurityQuestions } from 'dsoapi';

const CoursePageSecurityQuestions = ({
  courseData,
  setPageValid,
  showValidationInfo,
}) => {
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [answersIncorrect, setAnswersIncorrect] = useState(false);

  useEffect(() => {
    setPageValid(() => async () => {
      if (
        securityQuestions.length === 0 ||
        securityQuestions.some((q) => ![0, 1].includes(q.studentAnswer))
      ) {
        return false;
      }
      const request = {
        answers: securityQuestions,
      };
      const response = await coursePageAnswerSecurityQuestions(
        courseData.hashLink,
        request
      );
      if (!response.data.answersCorrect) {
        setAnswersIncorrect(true);
        return false;
      } else {
        return true;
      }
    });
  }, [securityQuestions, setPageValid, courseData.hashLink]);

  useEffect(() => {
    const questionsWithAnswers = courseData.securityQuestions.map(
      (question) => {
        return {
          ...question,
          studentAnswer: null,
        };
      }
    );
    setSecurityQuestions(questionsWithAnswers);
  }, [courseData]);

  const setStudentAnswer = (questionIndex, answer) => {
    setAnswersIncorrect(false);
    const questions = [...securityQuestions];
    const questionWithAnswer = {
      ...questions[questionIndex],
      studentAnswer: answer,
    };
    questions[questionIndex] = questionWithAnswer;
    setSecurityQuestions(questions);
  };

  const renderQuestions = () => {
    return securityQuestions.map((question, i) => {
      return (
        <div className="grouped fields" key={i}>
          <label>{`${i + 1}. ${question.questionText}`}</label>
          <div className="field">
            <div className="ui radio checkbox">
              <input
                className="hidden"
                type="radio"
                name={question.securityQuestionId}
                id={`question-${i + 1}-true`}
                onChange={(_) => setStudentAnswer(i, 1)}
                checked={question.studentAnswer === 1}
              />
              <label htmlFor={`question-${i + 1}-true`}>True</label>
            </div>
          </div>
          <div className="field">
            <div className="ui radio checkbox">
              <input
                className="hidden"
                type="radio"
                name={question.securityQuestionId}
                id={`question-${i + 1}-false`}
                onChange={(_) => setStudentAnswer(i, 0)}
                checked={question.studentAnswer === 0}
              />
              <label htmlFor={`question-${i + 1}-false`}>False</label>
            </div>
          </div>
          <br />
        </div>
      );
    });
  };

  const validationMessage = () => {
    if (securityQuestions.some((q) => ![0, 1].includes(q.studentAnswer))) {
      return 'Please answer both security questions.';
    } else if (answersIncorrect) {
      return 'Both answers are not correct';
    } else {
      return '';
    }
  };

  return (
    <>
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      <div className="ui form">{renderQuestions()}</div>
      {showValidationInfo && <p className="error">{validationMessage()}</p>}
    </>
  );
};

export default CoursePageSecurityQuestions;
