import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { courseEnroll, studentEmailExists, studentCreate } from 'dsoapi';
import { NextGatherInfoUrl, suffixOptions, UserContext } from 'dsoa-components';

const yupEmail = yup
  .string()
  .email('Invalid email address')
  .required('Email is required');

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(50, 'First name is too long')
      .required('First name is required'),
    lastName: yup
      .string()
      .max(50, 'Last name is too long')
      .required('Last name is required'),
    emailAddress: yupEmail,
    password: yup
      .string()
      .min(8, 'Password is too short')
      .matches(
        /^(?=.*\d)(?=.*\W)[\w\W]+$/,
        'Password must contain at least one number and one special character'
      )
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], "Passwords don't match")
      .required('Confirm password is required'),
  })
  .required();

const SignUp = ({ setTitle = () => {} }) => {
  const [debouncedEmail, setDebouncedEmail] = useState(null);
  const [emailTaken, setEmailTaken] = useState(false);
  const [selectedSuffix, setSelectedSuffix] = useState(suffixOptions[0].value);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setStudentInfo } = useContext(UserContext);

  const navigate = useNavigate();

  setTitle('Sign Up');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const watchEmail = watch('emailAddress');

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedEmail(watchEmail);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [watchEmail]);

  useEffect(() => {
    checkEmailTaken(debouncedEmail);
  }, [debouncedEmail]);

  useEffect(() => {
    setSelectedCourse(localStorage.getItem('signupCourse'));
  }, []);

  const onSubmit = async (data) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const emailRes = await studentEmailExists(data.emailAddress);
    const isTaken = emailRes.data.exists;
    if (!isTaken) {
      const request = {
        suffix: selectedSuffix,
        ...data,
        site: 'SDDA',
        adSource: localStorage.getItem('adsource') === 'google' ? 1 : 0,
      };
      const studentRes = await studentCreate(request);
      if (studentRes.status === 201) {
        localStorage.removeItem('adsource');
        localStorage.setItem('dsoaToken', studentRes.data.dsoapiToken);
        localStorage.setItem('dsoaClaims', JSON.stringify({}));
        setStudentInfo(studentRes.data);
        if (selectedCourse) {
          const enrollRes = await courseEnroll(selectedCourse, {
            siteRegisteredWith: 'seniordrivingdiscount',
          });
          localStorage.removeItem('signupCourse');
          const url = await NextGatherInfoUrl(
            enrollRes.data.userCourseEnrollmentId
          );
          navigate(url);
        } else {
          navigate('/dashboard');
        }
      }
    }
    setIsSubmitting(false);
  };

  const checkEmailTaken = async (email) => {
    yupEmail.isValid(email).then((valid) => {
      const checkEmail = async () => {
        if (valid) {
          const response = await studentEmailExists(email);
          if (response.data.exists) {
            setEmailTaken(true);
            return true;
          } else {
            setEmailTaken(false);
            return false;
          }
        }
      };
      return checkEmail();
    });
  };

  const redirectUri = encodeURI(`${window.location.origin}/oauth2/google`);

  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?\
scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&\
response_type=code&\
redirect_uri=${redirectUri}&\
client_id=209685082601-svmbch2vrqaqml2u5cuh3f1cs44dfjhs.apps.googleusercontent.com`;

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h3>Sign up with one click</h3>

        <div style={{ height: '5px' }} />
        <button
          className="ui large basic button"
          type="button"
          onClick={() => (window.location.href = googleAuthUrl)}
        >
          <span
            style={{
              backgroundImage: "url('/sign-in-with-google.svg')",
              backgroundRepeat: 'no-repeat',
              display: 'inline-block',
              height: '18px',
              width: '18px',
              marginRight: '8px',
            }}
          ></span>
          <span style={{ position: 'relative', top: '-2px' }}>
            Continue with Google
          </span>
        </button>
      </div>
      <div className="ui horizontal divider">Or</div>
      <div className="ui center aligned container">
        <h3>Sign up using your email</h3>
      </div>
      <div style={{ height: '15px' }} />
      <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="firstName">First Name</label>
              <input {...register('firstName')} placeholder="First Name" />
            </div>
            <p className="error">{errors.firstName?.message}</p>
            <div className="field">
              <label htmlFor="lastName">Last Name</label>
              <input {...register('lastName')} placeholder="Last Name" />
              <p className="error">{errors.lastName?.message}</p>
            </div>
            <div className="field">
              <label htmlFor="suffix">Suffix</label>
              <Dropdown
                name="suffix"
                style={{ maxHeight: '27rem' }}
                placeholder="None"
                fluid
                selection
                options={suffixOptions}
                onChange={(_, { value }) => setSelectedSuffix(value)}
              />
            </div>
          </div>
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="emailAddress">Email</label>
              <input {...register('emailAddress')} placeholder="Email" />
              <p className="error">{errors.emailAddress?.message}</p>
              {emailTaken && (
                <p className="error">
                  Email address already exists in system. Please click{' '}
                  <Link to="/login">here</Link> to login.
                </p>
              )}
            </div>
            <div className="field">
              <label htmlFor="password">
                Password{' '}
                <span style={{ opacity: '.5', fontWeight: 'normal' }}>
                  - at least 8 characters with a number and symbol
                </span>
              </label>
              <input
                {...register('password')}
                type="password"
                placeholder="Password"
              />
              <p className="error">{errors.password?.message}</p>
            </div>
            <div className="field">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                {...register('confirmPassword')}
                type="password"
                placeholder="Confirm Password"
              />
              <p className="error">{errors.confirmPassword?.message}</p>
            </div>
            <input
              className="ui right floated primary button"
              type="submit"
              value={isSubmitting ? 'Signing Up...' : 'Sign Up'}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </form>
      <br />
    </div>
  );
};

export default SignUp;
