import React from 'react';
import { Link } from 'react-router-dom';

const InProgressTab = ({ courses }) => {
  const renderList = () => {
    return courses.map((course) => {
      return (
        <div className="item" key={course.userCourseEnrollmentId}>
          {course.currentPageHashLink && (
            <div className="right floated content">
              <Link
                to={`/courseapp/${course.currentPageHashLink}`}
                className="ui primary button"
              >
                Resume
              </Link>
            </div>
          )}
          {!course.currentPageHashLink && (
            <div
              className="right floated content error"
              style={{ height: '36px' }}
            >
              course page status error
            </div>
          )}
          <div className="header">{course.displayName}</div>
        </div>
      );
    });
  };

  return (
    <>
      {courses.length === 0 && <p>No courses in progress.</p>}
      {courses.length > 0 && (
        <div className="ui middle aligned divided list">{renderList()}</div>
      )}
    </>
  );
};

export default InProgressTab;
