import React, { useRef } from 'react';
import VideoJS from './VideoJS';

const Video = ({ videoPath, posterPath, videoCaptions = [] }) => {
  const playerRef = useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: posterPath,
    sources: [
      {
        src: videoPath,
        type: 'video/mp4',
      },
    ],
  };

  const handlePlayerReady = (player) => {
    videoCaptions.forEach((vc) => {
      const track = {
        kind: 'captions',
        srclang: vc.srcLang,
        label: vc.label,
        src: vc.src,
      };
      player.addRemoteTextTrack(track, true);
    });
    playerRef.current = player;
  };

  return (
    <>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </>
  );
};

export default Video;
