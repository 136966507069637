import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { coursePageAnswerMiniquizQuestions } from 'dsoapi';

const CoursePageMiniquiz = ({
  courseData,
  setPageValid,
  showValidationInfo,
}) => {
  const [questions, setQuestions] = useState([]);
  const [answersIncorrect, setAnswersIncorrect] = useState(false);

  const bottomRef = useRef(null);

  useEffect(() => {
    setQuestions(courseData.miniquiz.questions);
  }, [courseData]);

  useEffect(() => {
    setPageValid(() => async () => {
      if (questions.some((q) => !q.studentAnswer)) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
      const answers = questions.map((q) => ({
        miniquizQuestionId: q.miniquizQuestionId,
        studentAnswer: q.studentAnswer,
      }));
      const response = await coursePageAnswerMiniquizQuestions(
        courseData.hashLink,
        { answers }
      );
      if (!response.data.answersCorrect) {
        setAnswersIncorrect(true);
        return false;
      } else {
        return true;
      }
    });
  }, [questions, answersIncorrect, setPageValid, courseData.hashLink]);

  const setStudentAnswer = (miniquizAnswerId) => {
    const answeredQuestions = questions.map((question) => {
      if (
        question.answers.some(
          (ans) => ans.miniquizAnswerId === miniquizAnswerId
        )
      ) {
        return { ...question, studentAnswer: miniquizAnswerId };
      } else {
        return { ...question };
      }
    });
    setQuestions(answeredQuestions);
  };

  const renderQuestions = () => {
    return questions.map((question, i) => {
      return (
        <div className="grouped fields" key={i}>
          <label>{`${i + 1}. ${question.questionText}`}</label>
          {renderAnswers(question.answers, question.studentAnswer)}
        </div>
      );
    });
  };

  const renderAnswers = (answers, studentAnswer) => {
    return answers.map((answer) => {
      return (
        <div className="field" key={answer.miniquizAnswerId}>
          <div className="ui radio checkbox">
            <input
              className="hidden"
              type="radio"
              name={answers.miniquizAnswerId}
              id={`answer-${answer.miniquizAnswerId}`}
              onChange={(_) => setStudentAnswer(answer.miniquizAnswerId)}
              checked={studentAnswer === answer.miniquizAnswerId}
            />
            <label htmlFor={`answer-${answer.miniquizAnswerId}`}>
              {answer.answerText}
            </label>
          </div>
        </div>
      );
    });
  };

  const answersIncorrectMarkup = (
    <>
      You did not get all of the answers correct. <br />
      Please click{' '}
      <Link to={`/courseapp/${courseData.miniquiz.chapterStartHashLink}`}>
        here
      </Link>{' '}
      to go to the beginning of this chapter.
    </>
  );

  const validationMessage = () => {
    if (questions.some((q) => !q.studentAnswer)) {
      return 'Please answer all questions.';
    }
    return '';
  };

  return (
    <>
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      {answersIncorrect && (
        <h2 className="ui center aligned header">{answersIncorrectMarkup}</h2>
      )}
      {!answersIncorrect && <div className="ui form">{renderQuestions()}</div>}
      {showValidationInfo && <p className="error">{validationMessage()}</p>}
      <div ref={bottomRef} />
      <br />
    </>
  );
};

export default CoursePageMiniquiz;
