/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import RegisterTab from './RegisterTab';
import InProgressTab from './InProgressTab';
import CompleteTab from './CompleteTab';
import { coursePageQuizScore, studentDashboard } from 'dsoapi';

const REGISTER = 'REGISTER';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETE = 'COMPLETE';
const emptyDashboardData = {
  registrationInProgress: [],
  enrolled: [],
  completed: [],
};

const Dashboard = ({ setTitle = () => {} }) => {
  const [dashboardData, setDashboardData] = useState(emptyDashboardData);
  const [activeTab, setActiveTab] = useState(REGISTER);
  const [passedQuizMessage, setPassedQuizMessage] = useState('');
  const { '*': selectedTab } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quizScoreFrom = queryParams.get('quizScoreFrom');

  setTitle('Dashboard');

  useEffect(() => {
    const fetchDashboardData = async () => {
      const response = await studentDashboard();
      setDashboardData(response.data);
    };
    fetchDashboardData();
  }, []);

  useEffect(() => {
    const fetchQuizScore = async () => {
      const {
        data: { score },
      } = await coursePageQuizScore(quizScoreFrom);
      if (score) {
        let message = `Congratulations you have passed your exam with a score of ${score}%.  `;
        message += 'You may download your certificate below.';
        setPassedQuizMessage(message);
      }
    };
    if (quizScoreFrom) {
      fetchQuizScore();
    }
  }, [quizScoreFrom]);

  useEffect(() => {
    const initialActiveTab = () => {
      if (selectedTab) {
        switch (selectedTab.toUpperCase()) {
          case REGISTER:
            return REGISTER;
          case IN_PROGRESS:
            return IN_PROGRESS;
          case COMPLETE:
            return COMPLETE;
          default:
            return REGISTER;
        }
      }
      if (dashboardData.registrationInProgress.length > 0) {
        return REGISTER;
      }
      if (dashboardData.enrolled.length > 0) {
        return IN_PROGRESS;
      }
      if (dashboardData.completed.length > 0) {
        return COMPLETE;
      }
      return REGISTER;
    };
    setActiveTab(initialActiveTab());
  }, [dashboardData, selectedTab]);

  const anyDashboardRecords = () => {
    const dashboardRecordCount =
      dashboardData.registrationInProgress.length +
      dashboardData.enrolled.length +
      dashboardData.completed.length;
    return dashboardRecordCount > 0;
  };

  return (
    <>
      <div className="ui main container">
        {passedQuizMessage && (
          <div style={{ textAlign: 'center' }}>
            <h3>{passedQuizMessage}</h3>
          </div>
        )}
        <div className="ui three item menu">
          <a
            className={`item${activeTab === REGISTER ? ' active' : ''}`}
            onClick={() => setActiveTab(REGISTER)}
          >
            Register
          </a>
          <a
            className={`item${activeTab === IN_PROGRESS ? ' active' : ''}`}
            onClick={() => setActiveTab(IN_PROGRESS)}
          >
            In Progress
          </a>
          <a
            className={`item${activeTab === COMPLETE ? ' active' : ''}`}
            onClick={() => setActiveTab(COMPLETE)}
          >
            Complete
          </a>
        </div>
        {activeTab === REGISTER && (
          <RegisterTab
            courses={dashboardData.registrationInProgress}
            anyDashboardRecords={anyDashboardRecords()}
          />
        )}
        {activeTab === IN_PROGRESS && (
          <InProgressTab courses={dashboardData.enrolled} />
        )}
        {activeTab === COMPLETE && (
          <CompleteTab
            courses={dashboardData.completed}
            anyDashboardRecords={anyDashboardRecords()}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
