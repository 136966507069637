import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Segment, Menu, Sidebar } from 'semantic-ui-react';
import {
  coursePage as getCoursePage,
  coursePagePrevious,
  coursePageNext,
  coursePageCompleteCourse,
} from 'dsoapi';
import BottomMenu from './BottomMenu';
import CourseAudio from './CourseAudio';
import CoursePage from './CoursePage';
import { useDisableCopyFeatures } from './UseDisableCopyFeatures';
import TableOfContents from './TableOfContents';

const emptyCoursePageData = {
  courseAbbreviation: '',
  coursePageType: 'Init',
  coursePageHtml: '',
  title: '',
  courseTotalSeconds: 0,
  courseSecondsComplete: 0,
  pageTotalSeconds: 0,
  pageSecondsRemaining: 0,
  hasPreviousPage: false,
  hashLink: '',
  audioPath: null,
  videoPath: null,
  videoPosterPath: null,
  tableOfContents: [],
};

const Course = ({ setTitle = () => {} }) => {
  const [courseData, setCourseData] = useState(emptyCoursePageData);
  const [tocVisible, setTocVisible] = useState(false);
  const [audioPanelVisible, setAudioPanelVisible] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageIsSubmitting, setPageIsSubmitting] = useState(false);
  const [pageValid, setPageValid] = useState(() => () => false);
  const [showValidationInfo, setShowValidationInfo] = useState(false);
  const { hashLink } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourse = async () => {
      setPageIsLoading(true);
      const { data } = await getCoursePage(hashLink);
      if (data.redirectHashLink) {
        navigate(`/courseapp/${data.redirectHashLink}`);
        return;
      }
      if (data.error) {
        navigate('/dashboard');
        return;
      }
      setCourseData(data);
      setTitle(data.title);
      setPageValid(() => false);
      setShowValidationInfo(false);
      setPageIsLoading(false);
    };
    fetchCourse();
  }, [hashLink, navigate]);

  useDisableCopyFeatures(courseData.courseAbbreviation === 'TVSCA');

  const maybeOnBack = async () => {
    if (!pageIsLoading && courseData.hasPreviousPage) {
      const response = await coursePagePrevious(hashLink);
      navigate(`/courseapp/${response.data.hashLink}`);
    }
  };

  const maybeOnNext = async () => {
    if (!pageIsLoading) {
      setPageIsSubmitting(true);
      const isValid = await pageValid();
      if (!isValid) {
        setShowValidationInfo(true);
        setPageIsSubmitting(false);
        return;
      }
      const response = await coursePageNext(hashLink);
      if (response.data.hashLink) {
        setPageIsSubmitting(false);
        navigate(`/courseapp/${response.data.hashLink}`);
      } else {
        await coursePageCompleteCourse(hashLink);
        setPageIsSubmitting(false);
        navigate(`/dashboard/complete?quizScoreFrom=${hashLink}`);
      }
    }
  };

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="left"
        vertical
        onHide={() => setTocVisible(false)}
        visible={tocVisible}
      >
        <TableOfContents rows={courseData.tableOfContents} />
      </Sidebar>
      <Sidebar
        as={Segment}
        animation="overlay"
        direction="bottom"
        onHide={() => setAudioPanelVisible(false)}
        visible={audioPanelVisible}
      >
        <CourseAudio
          setAudioPanelVisible={setAudioPanelVisible}
          audioPath={courseData.audioPath}
          setAudioPlaying={setAudioPlaying}
          showTimeRemaining={courseData.showTimeRemaining}
          courseTotalSeconds={courseData.courseTotalSeconds}
          courseSecondsComplete={courseData.courseSecondsComplete}
          pageSecondsRemaining={courseData.pageSecondsRemaining}
        />
      </Sidebar>
      <Sidebar.Pushable>
        <Sidebar.Pusher>
          <div
            id="course-container"
            className="ui container"
            style={{
              heaight: '100%',
              userSelect:
                courseData.courseAbbreviation === 'TVSCA' ? 'none' : 'auto',
            }}
          >
            <CoursePage
              courseData={courseData}
              setPageValid={setPageValid}
              showValidationInfo={showValidationInfo}
            />
            <div style={{ height: '63px' }}></div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <BottomMenu
        coursePageType={courseData.coursePageType}
        pageTotalSeconds={courseData.pageTotalSeconds}
        pageSecondsRemaining={courseData.pageSecondsRemaining}
        hasPreviousPage={courseData.hasPreviousPage}
        hasNextPage={
          courseData.hasNextPage || courseData.quizReview?.passed === false
        }
        onToggleToc={() => setTocVisible(!tocVisible)}
        pageHasAudio={courseData.audioPath !== null}
        setAudioPanelVisible={setAudioPanelVisible}
        audioPlaying={audioPlaying}
        onBack={() => maybeOnBack()}
        onNext={() => maybeOnNext()}
        pageIsSubmitting={pageIsSubmitting}
        hashLink={hashLink}
      />
    </>
  );
};

export default Course;
