import React, { useState, useEffect } from 'react';
import { Progress } from 'semantic-ui-react';

const CourseAudio = ({
  setAudioPanelVisible,
  audioPath,
  setAudioPlaying,
  showTimeRemaining,
  courseTotalSeconds,
  courseSecondsComplete,
  pageSecondsRemaining,
}) => {
  const [secondsComplete, setSecondsComplete] = useState(0);
  const [progressActive, setProgressActive] = useState(false);

  useEffect(() => {
    setSecondsComplete(courseSecondsComplete);
    setProgressActive(pageSecondsRemaining > 0);
  }, [pageSecondsRemaining, courseSecondsComplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsComplete < courseSecondsComplete + pageSecondsRemaining) {
        setSecondsComplete(secondsComplete + 1);
      } else {
        setProgressActive(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [secondsComplete, courseSecondsComplete, pageSecondsRemaining]);

  const percentComplete =
    courseTotalSeconds > 0
      ? Math.round((secondsComplete / courseTotalSeconds) * 100)
      : 0;

  const timeRemaining = () => {
    const duration = courseTotalSeconds - secondsComplete;
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration / 60) % 60;
    if (hours > 0) {
      const hoursPlural = hours !== 1 ? 's' : '';
      const minutesPlural = minutes !== 1 ? 's' : '';
      return `${hours} hour${hoursPlural} ${minutes} minute${minutesPlural} remaining`;
    } else if (minutes > 0) {
      const minutesPlural = minutes > 1 ? 's' : '';
      return `${minutes} minute${minutesPlural} remaining`;
    }
    return '';
  };

  return (
    <div className="ui center aligned container">
      <div className="clickable" onClick={() => setAudioPanelVisible(false)}>
        <i className="large angle down icon"></i>
      </div>
      {audioPath !== null && (
        <>
          <h4 className="ui dividing header" style={{ textAlign: 'left' }}>
            Audio
          </h4>
          <audio
            controls
            controlsList="nodownload"
            type="audio/mpeg"
            style={{ width: '100%' }}
            src={audioPath}
            onPlay={() => setAudioPlaying(true)}
            onPause={() => setAudioPlaying(false)}
            onEnded={() => setAudioPlaying(false)}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </>
      )}
      <h4 className="ui dividing header" style={{ textAlign: 'left' }}>
        Course Progress
      </h4>
      <Progress
        progress="percent"
        percent={percentComplete}
        active={progressActive}
      >
        {showTimeRemaining && timeRemaining()}
      </Progress>
    </div>
  );
};

export default CourseAudio;
