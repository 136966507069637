import React, { useState, useEffect } from 'react';
import { courseByAbbreviation } from 'dsoapi';

const SDDATN = ({ setCourseAbbreviation, setIsValid }) => {
  const [price, setPrice] = useState('');

  useEffect(() => {
    setCourseAbbreviation('SDDATN');
    setIsValid(true);
  }, [setCourseAbbreviation, setIsValid]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await courseByAbbreviation('SDDATN');
      const responsePrice = response.data.price;
      setPrice(`$${parseFloat(responsePrice).toFixed(2)}`);
    };
    fetchData();
  }, [price]);

  return (
    <>
      <p style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}>
        Senior Driving Discount of America
        <br />
        <span style={{ fontWeight: 'bold' }}> {price}</span> Accident Prevention
        Course (8 Hour)
      </p>
      <p>
        The Senior Driving Discount of America Mature Driver Improvement Course
        is specifically designed for drivers 50 years of age and older and is
        100% online and lasts approximately 8 hours for the Mature Driver
        Accident Prevention Course mandated by the state of Tennessee Department
        of Safety Homeland Security. Once the course is completed, a certificate
        of completion will immediately be sent to the email address you
        registered with for free! You will always have a digital copy to
        download through your account.
      </p>
      <p>
        Tennessee State Law mandates that completion of this course will reduce
        the amount you pay for your auto insurance. The successful completion of
        this defensive driving course shows a vehicle insurance company that the
        driver is skilled in avoiding accidents and is less of a liability on
        the road.
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>
              Insurance companies will typically provide up to a 15% discount on
              your car insurance
            </li>
            <li>
              Discount and certificate for the Mature Driver Accident Prevention
              Course are good for 3 years
            </li>
            <li>
              Saving the average individual up to $500 on their car insurance
            </li>
            <li>
              Customers can reach a state-certified instructor anytime day or
              night
            </li>
            <li>
              Our course can be taken at your leisure, 24 hours a day, 7 days a
              week, via PC, Mobile Device, or Tablet.
            </li>
            <li>
              Start and resume on your own time from any device anywhere in the
              world!
            </li>
            <li>Award Winning! Guaranteed The Best Price Online!</li>
            <li>Tennessee DSHS Approved Course</li>
            <li>Final price, No hidden fees!</li>
          </ul>
        </div>
        <div className="six wide column">
          <img
            src="https://static-content.fedsafe.com/images/stateseals/tn.png"
            className="ui image"
            style={{ margin: '0 auto', maxHeight: '300px' }}
            alt="ca"
          ></img>
        </div>
      </div>
    </>
  );
};

export default SDDATN;
