import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = ({ redirectUrl = '/', setTitle = () => {} }) => {
  const navigate = useNavigate();

  setTitle('Page Not Found');

  const handleRedirect = () => {
    navigate(redirectUrl);
  };

  return (
    <div className="ui main container">
      <div className="ui center aligned container">
        <p>The requested page could not be found.</p>
        <button className="ui primary button" onClick={handleRedirect}>
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
