import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { courseAddSecurityAnswers, courseSecurityQuestions } from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const GatherSecurityQuestions = ({ uceId }) => {
  const [securityQuestionsName, setSecurityQuestionsName] = useState('');
  const [securityQuestions, setSecurityQuestions] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      const securityQuestions = await courseSecurityQuestions(uceId);
      const questions = securityQuestions.data.securityQuestions.reduce(
        (map, question) => {
          map[question.securityQuestionId] = {
            ...question,
            studentAnswer: null,
          };
          return map;
        },
        {}
      );
      setSecurityQuestions(questions);
      setSecurityQuestionsName(securityQuestions.data.name);
    };
    fetchQuestions();
  }, [uceId]);

  const pageValid = () =>
    Object.values(securityQuestions).every((sq) => sq.studentAnswer !== null);

  const setStudentAnswer = (id, answer) => {
    const questions = { ...securityQuestions };
    questions[id].studentAnswer = answer;
    setSecurityQuestions(questions);
  };

  const onSubmit = async () => {
    if (!pageValid()) {
      setShowValidation(true);
    } else {
      setIsSubmitting(true);
      const answers = Object.values(securityQuestions).map((sq) => {
        return {
          securityQuestionId: sq.securityQuestionId,
          studentAnswer: sq.studentAnswer,
        };
      });
      const request = { answers: answers };
      await courseAddSecurityAnswers(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setIsSubmitting(false);
      navigate(url);
    }
  };

  const validationMessage = () => {
    const unansweredQuestions = Object.values(securityQuestions)
      .filter((sq) => sq.studentAnswer === null)
      .map((sq) => sq.securityQuestionId)
      .join();
    return unansweredQuestions.length > 0
      ? `Please answer the following questions: ${unansweredQuestions}`
      : '';
  };

  const renderQuestions = () => {
    return Object.values(securityQuestions).map((question) => {
      return (
        <div className="grouped fields" key={question.securityQuestionId}>
          <label>
            {showValidation && question.studentAnswer === null && (
              <span className="error">* </span>
            )}
            {`${question.securityQuestionId}. ${question.questionText}`}
          </label>
          <div className="field">
            <div className="ui radio checkbox">
              <input
                className="hidden"
                type="radio"
                name={question.securityQuestionId}
                id={`question-${question.securityQuestionId}-yes`}
                onChange={(_) =>
                  setStudentAnswer(question.securityQuestionId, 1)
                }
                checked={question.studentAnswer === 1}
              />
              <label htmlFor={`question-${question.securityQuestionId}-yes`}>
                True
              </label>
            </div>
          </div>
          <div className="field">
            <div className="ui radio checkbox">
              <input
                className="hidden"
                type="radio"
                name={question.securityQuestionId}
                id={`question-${question.securityQuestionId}-no`}
                onChange={(_) =>
                  setStudentAnswer(question.securityQuestionId, 0)
                }
                checked={question.studentAnswer === 0}
              />
              <label htmlFor={`question-${question.securityQuestionId}-no`}>
                False
              </label>
            </div>
          </div>
          <br />
        </div>
      );
    });
  };

  return (
    <>
      <div className="ui container">
        <div className="ui stackable grid">
          <div className="two wide column"></div>
          <div className="twelve wide column">
            <div className="ui dividing header">
              <span style={{ fontSize: '1.05rem' }}>
                {securityQuestionsName}{' '}
              </span>
              <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                - These questions will be prompted during the course to verify
                the student per the state requirements.
              </span>
            </div>
            {renderQuestions()}
            <br />
            <button
              className="fluid ui primary button"
              onClick={() => onSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <br />
            {showValidation && (
              <span className="error">{validationMessage()}</span>
            )}
          </div>
          <div className="two wide column"></div>
        </div>
      </div>
    </>
  );
};

export default GatherSecurityQuestions;
