import React from 'react';

const CcSecurityCodeInput = ({ id, placeholder, value, onChange }) => {
  const onMaybeChange = (secCode) => {
    if (isNaN(secCode) || secCode.length > 4) {
      return;
    }
    onChange(secCode);
  };

  return (
    <input
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onMaybeChange(e.target.value)}
      type="text"
      inputMode="numeric"
      pattern="^\d*$"
    />
  );
};

export default CcSecurityCodeInput;
