import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { courseAddBdi8tnInfo, courseTnCounties, studentData } from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const GatherInfoBDI8TN = ({ uceId }) => {
  const [citationNumber, setCitationNumber] = useState('');
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [dlNumber, setDlNumber] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const tnCountiesPromise = courseTnCounties();
      const studentDataPromise = studentData();
      const tnCountiesResp = await tnCountiesPromise;
      const studentResp = await studentDataPromise;
      setCounties(tnCountiesResp.data);
      setDlNumber(studentResp.data.driverLicenseNumber);
    };
    fetchData();
  }, [uceId]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    if (dlNumber) {
      setSubmitting(true);
      const request = {
        citationNumber,
        countyReceived: selectedCounty,
        driverLicenseNumber: dlNumber,
      };
      await courseAddBdi8tnInfo(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setSubmitting(false);
      navigate(url);
    }
  };

  return (
    <form className="ui form" onSubmit={onSubmit}>
      <div className="ui container">
        <h4 className="ui dividing header">Enter Information</h4>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <div className="ui checkbox">
                <input type="checkbox" id="ticketCheckbox" className="hidden" />
                <label htmlFor="ticketCheckbox">No Ticket Received</label>
              </div>
            </div>
            <div className="field">
              <label htmlFor="citationNumber">Citation Number</label>
              <input
                name="citationNumber"
                placeholder="Citation Number"
                value={citationNumber}
                onChange={(e) => setCitationNumber(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="countyReceived">County Received</label>
              <Dropdown
                name="countyReceived"
                style={{ maxHeight: '27rem' }}
                placeholder="Select county received"
                fluid
                selection
                options={counties}
                value={selectedCounty}
                onChange={(_, { value }) => setSelectedCounty(value)}
              />
            </div>
          </div>
          <div className="eight wide column">
            <div className="field">
              <div className="ui checkbox"></div>
            </div>
            <div className="field">
              <label htmlFor="dlNumber">Drivers License Number</label>
              <input
                name="dlNumber"
                placeholder="Drivers License Number"
                value={dlNumber}
                onChange={(e) => setDlNumber(e.target.value)}
              />
              {showValidation && !dlNumber && (
                <p className="error">driver license number is required</p>
              )}
            </div>
            <div style={{ height: '27px' }} />
            <input
              className="ui primary button right floated content"
              type="submit"
              value={submitting ? 'Submitting...' : 'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GatherInfoBDI8TN;
