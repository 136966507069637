import React, { useState, useEffect } from 'react';
import { courseByAbbreviation } from 'dsoapi';

const SDDADE = ({ courseAbbreviation, setCourseAbbreviation, setIsValid }) => {
  const [dePrice, setDePrice] = useState('');
  const [derPrice, setDerPrice] = useState('');

  useEffect(() => {
    setIsValid(['SDDADE', 'SDDADER'].includes(courseAbbreviation));
  }, [setIsValid, courseAbbreviation]);

  useEffect(() => {
    const fetchData = async () => {
      const [caResponse, carResponse] = await Promise.all([
        courseByAbbreviation('SDDADE'),
        courseByAbbreviation('SDDADER'),
      ]);
      const caResponsePrice = caResponse.data.price;
      const carResponsePrice = carResponse.data.price;
      setDePrice(`$${parseFloat(caResponsePrice).toFixed(2)}`);
      setDerPrice(`$${parseFloat(carResponsePrice).toFixed(2)}`);
    };
    fetchData();
  }, [dePrice, derPrice]);

  return (
    <>
      <p style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}>
        Senior Driving Discount of America Accident Prevention Defensive Driving
        Course
        <br />
        <span style={{ fontWeight: 'bold' }}>{dePrice} </span>
        Basic Course (6 Hour)
        <br />
        <span style={{ fontWeight: 'bold' }}>{derPrice} </span>
        Refresher Course (3 Hour)
      </p>
      <p>
        If you are a driver of a motor vehicle and complete this approved
        Accident Prevention Defensive Driving Course, you may receive 10 percent
        off of a portion of your auto insurance for 3 years! If you take a
        refresher Defensive Driving Course within 180 days of the end of the 3
        year period, you may receive a 15 percent discount!
        <br />
        <br />
        This Delaware Accident Prevention Defensive Driving Course is approved
        by the Delaware Department of Motor Vehicles (DMV) and the Delaware
        Insurance Department to provide a minimum 10% car insurance discount and
        a 3 point credit on your license.
        <br />
        <br />
        An insured who completes the refresher course after the expiration of
        the 3 year period shall lose the 10% or 15% discount on the expiration
        date, but shall receive the 15% discount effective on the date of
        completion of the refresher course, if completion is within 2 years of
        the expiration date.
        <br />
        <br />
        6 Hour Basic - This course will give you a 10% insurance reduction on
        your auto liability insurance. This reduction will be in place for 3
        years and you will also receive a 3-point credit on your Delaware
        driving record. Select this course if you have never completed a
        Defensive Driving Insurance Discount Course.
        <br />
        <br />3 Hour Refresher - Only select this option if you already
        completed the Basic Accident Prevention Defensive Driving Course.
        Completion will result in 15% insurance reduction for an additional 3
        years. A 3-point credit on your driver record will be extended for
        another 3 years.
      </p>
      <p>
        Delaware State Law mandates that completion of this course will reduce
        the amount you pay for your auto insurance. The successful completion of
        this defensive driving course shows a vehicle insurance company that the
        driver is skilled in avoiding accidents and is less of a liability on
        the road.
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>
              Insurance companies will typically provide up to a 10-15% discount
              on your car insurance
            </li>
            <li>Discount and certificate are good for 3 years</li>
            <li>
              Saving the average individual up to $500 on their car insurance
            </li>
            <li>
              Customers can reach a state-certified instructor anytime day or
              night
            </li>
            <li>
              Our course can be taken at your leisure, 24 hours a day, 7 days a
              week, via PC, Mobile Device, or Tablet.{' '}
            </li>
            <li>
              Start and resume on your own time from any device anywhere in the
              world!
            </li>
            <li>
              We also notify the DMV of your completion which can result in a 3
              point credit on your license!
            </li>
            <li>Award Winning! Guaranteed The Best Price Online!</li>
            <li>Delaware DMV Approved Course</li>
            <li>Final price, No hidden fees!</li>
          </ul>
        </div>
        <div className="six wide column">
          <img
            src="https://static-content.fedsafe.com/images/stateseals/de.png"
            className="ui image"
            style={{ margin: '0 auto', maxHeight: '300px' }}
            alt="ca"
          ></img>
          <div style={{ height: '30px' }}></div>
        </div>
      </div>
      <p style={{ fontWeight: 'bold' }}>
        Please Select The Course For You Below:
      </p>
      <div style={{ margin: '15px' }} className="ui radio checkbox">
        <input
          id="sddadeRadio"
          type="radio"
          value="SDDADE"
          checked={courseAbbreviation === 'SDDADE'}
          onChange={(e) => setCourseAbbreviation(e.target.value)}
        />
        <label
          htmlFor="sddadeRadio"
          style={{ display: 'block', fontSize: 'large' }}
        >
          <span style={{ fontWeight: 'bold' }}>{dePrice} </span>
          Basic Course (6 Hour)
        </label>
      </div>
      <div
        style={{ marginLeft: '15px', marginBottom: '15px' }}
        className="ui radio checkbox"
      >
        <input
          id="sddaderRadio"
          type="radio"
          value="SDDADER"
          checked={courseAbbreviation === 'SDDADER'}
          onChange={(e) => setCourseAbbreviation(e.target.value)}
        />
        <label
          htmlFor="sddaderRadio"
          style={{ display: 'block', fontSize: 'large' }}
        >
          <span style={{ fontWeight: 'bold' }}>{derPrice} </span>
          Refresher Course (3 Hour)
        </label>
      </div>
    </>
  );
};

export default SDDADE;
