import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { studentLogin } from 'dsoapi';
import UserContext from './UserContext';

const Login = ({ setTitle = () => {} }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { setStudentInfo } = useContext(UserContext);

  const navigate = useNavigate();

  setTitle('Login');

  const onSetEmailAddress = (email) => {
    setEmailAddress(email);
    setShowLoginError(false);
  };

  const onSetPassword = (password) => {
    setPassword(password);
    setShowLoginError(false);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    onLogin();
  };

  const onLogin = async () => {
    setIsLoggingIn(true);
    const response = await studentLogin({ emailAddress, password });
    if (
      response &&
      response.status === 200 &&
      response.data.success === false &&
      response.data.reason === 'OAuthUser'
    ) {
      window.location.href = googleAuthUrl;
      return;
    }
    if (response && response.status === 200 && response.data.success) {
      localStorage.setItem('dsoaToken', response.data.dsoapiToken);
      localStorage.setItem('dsoaClaims', JSON.stringify(response.data.claims));
      setStudentInfo(response.data);
      setIsLoggingIn(false);
      navigate('/dashboard');
    } else {
      setIsLoggingIn(false);
      setShowLoginError(true);
    }
  };

  const redirectUri = encodeURI(`${window.location.origin}/oauth2/google`);

  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?\
scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&\
response_type=code&\
redirect_uri=${redirectUri}&\
client_id=209685082601-svmbch2vrqaqml2u5cuh3f1cs44dfjhs.apps.googleusercontent.com`;

  return (
    <div
      className="ui middle aligned center aligned grid"
      style={{ height: '100%' }}
    >
      <div className="column" style={{ maxWidth: '450px' }}>
        <h3 className="ui image header">
          <div className="content">Log in</div>
        </h3>
        {showLoginError && (
          <div className="ui error message">Invalid login information</div>
        )}
        <form className="ui large form" onSubmit={onFormSubmit}>
          <div className="ui segment">
            <div className="field">
              <div className="ui left icon input">
                <i className="user icon"></i>
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail address"
                  value={emailAddress}
                  onChange={(e) => onSetEmailAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="ui left icon input">
                <i className="lock icon"></i>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => onSetPassword(e.target.value)}
                />
              </div>
            </div>
            <input
              className="ui fluid large blue submit button"
              type="submit"
              value={isLoggingIn ? 'Logging in...' : 'Log In'}
              disabled={isLoggingIn}
            />
            <div style={{ height: '10px' }} />
            <button
              className="ui fluid large blue button"
              type="button"
              onClick={() => navigate('/forgotpassword')}
            >
              Forgot Password
            </button>
            <div style={{ height: '8px' }} />
            <div className="ui horizontal divider">Or</div>
            <button
              className="ui fluid basic button"
              type="button"
              onClick={() => (window.location.href = googleAuthUrl)}
            >
              <span
                style={{
                  backgroundImage: "url('/sign-in-with-google.svg')",
                  backgroundRepeat: 'no-repeat',
                  display: 'inline-block',
                  height: '18px',
                  width: '18px',
                  marginRight: '8px',
                }}
              ></span>
              <span style={{ position: 'relative', top: '-2px' }}>
                Continue with Google
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
