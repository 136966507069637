import React, { useState, useEffect } from 'react';
import { courseByAbbreviation } from 'dsoapi';

const SDDAOR = ({ setCourseAbbreviation, setIsValid }) => {
  const [price, setPrice] = useState('');

  useEffect(() => {
    setCourseAbbreviation('SDDAOR');
    setIsValid(true);
  }, [setCourseAbbreviation, setIsValid]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await courseByAbbreviation('SDDAOR');
      const responsePrice = response.data.price;
      setPrice(`$${parseFloat(responsePrice).toFixed(2)}`);
    };
    fetchData();
  }, [price]);

  return (
    <>
      <p style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}>
        Oregon Accident Prevention Mature Driver Improvement Course
        <br />
        <span style={{ fontWeight: 'bold' }}>{price}</span> Best Price Online
      </p>
      <p>
        The Driving School of America, Oregon Accident Prevention Mature Driver
        Improvement Course, is specifically designed for drivers 55 years of age
        and older and is 100% online and lasts approximately 6 hour per state
        DMV guidelines. However, there are no timers, and you can proceed at
        your own pace. Once the course is completed, a certificate of completion
        will immediately be sent to the email address you registered with for
        free! You will always have a digital copy to download through your
        account.
      </p>
      <p>
        This Oregon Department of Transportation DMV Approved course states any
        persons 55 years of age and older may qualify for an insurance discount
        upon completion of this program. Based on Oregon Revised Statutes ORS
        742.490 through ORS 742.496. The successful completion of this Accident
        Prevention defensive driving course shows a vehicle insurance company
        that the driver is skilled in avoiding accidents and is less of a
        liability on the road.
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>
              Insurance companies will typically provide up to a 15% discount on
              your car insurance
            </li>
            <li>
              Discount and certificate for the Mature Driver Accident Prevention
              Course are good for up to 3 years
            </li>
            <li>
              Can save the average individual up to $500 on their car insurance
            </li>
            <li>
              Customers can reach a state-certified instructor anytime day or
              night
            </li>
            <li>
              Our course can be taken at your leisure, 24 hours a day, 7 days a
              week, via PC, Mobile Device, or Tablet.{' '}
            </li>
            <li>
              Start and resume on your own time from any device anywhere in the
              world!
            </li>
            <li>Guaranteed The Best Price Online!</li>
            <li>Oregon DMV Approved Course</li>
            <li>No Course Timers!</li>
            <li>Final price, No hidden fees!</li>
          </ul>
        </div>
        <div className="six wide column">
          <img
            src="https://static-content.fedsafe.com/images/stateseals/or.png"
            className="ui image"
            style={{ margin: '0 auto', maxHeight: '300px' }}
            alt="or"
          ></img>
        </div>
      </div>
    </>
  );
};

export default SDDAOR;
