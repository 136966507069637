import React, { useState, useEffect, useRef } from 'react';
import { coursePageAnswerChapterquizQuestions } from 'dsoapi';

const CoursePageChapterquiz = ({
  courseData,
  setPageValid,
  showValidationInfo,
}) => {
  const [questions, setQuestions] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);
  const [passed, setPassed] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const topRef = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    setQuestions(courseData.chapterquiz.questions);
  }, [courseData]);

  useEffect(() => {
    setPageValid(() => async () => {
      // all questions are answered
      if (questions.some((q) => !q.studentAnswer)) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        setAttemptedSubmit(true);
        return false;
      }
      if (quizSubmitted) {
        if (passed) {
          return true;
        } else {
          setSolutions([]);
          setQuestions(newQuestions);
          setAttemptedSubmit(false);
          setQuizSubmitted(false);
          return false;
        }
      } else {
        const answers = questions.map((q) => ({
          chapterquizQuestionId: q.chapterquizQuestionId,
          studentAnswer: q.studentAnswer,
        }));
        const response = await coursePageAnswerChapterquizQuestions(
          courseData.hashLink,
          { answers }
        );
        setQuizSubmitted(true);
        setPassed(response.data.passed);
        setSolutions(response.data.solutions);
        setNewQuestions(response.data.newChapterquiz?.questions ?? []);
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
    });
  }, [
    questions,
    setPageValid,
    courseData.hashLink,
    quizSubmitted,
    passed,
    newQuestions,
  ]);

  const setStudentAnswer = (chapterquizAnswerId) => {
    const answeredQuestions = questions.map((question) => {
      if (
        question.answers.some(
          (ans) => ans.chapterquizAnswerId === chapterquizAnswerId
        )
      ) {
        return { ...question, studentAnswer: chapterquizAnswerId };
      } else {
        return { ...question };
      }
    });
    setQuestions(answeredQuestions);
  };

  const renderQuestions = () => {
    return questions.map((question, i) => {
      const solution = solutions.find(
        (s) => s.chapterquizQuestionId === question.chapterquizQuestionId
      );
      return (
        <div className="grouped fields" key={i}>
          <label>{`${i + 1}. ${question.questionText}`}</label>
          {renderAnswers(question.answers, question.studentAnswer)}
          {solution && (
            <div style={{ fontStyle: 'italic' }}>
              <p>
                <i
                  className="thumbs up outline icon"
                  style={{ color: '#0281B1' }}
                />{' '}
                {solution.explanation}
              </p>
            </div>
          )}
        </div>
      );
    });
  };

  const renderAnswers = (answers, studentAnswer) => {
    return answers.map((answer) => {
      return (
        <div className="field" key={answer.chapterquizAnswerId}>
          <div className="ui radio checkbox">
            <input
              className="hidden"
              type="radio"
              name={answers.chapterquizAnswerId}
              id={`answer-${answer.chapterquizAnswerId}`}
              onChange={(_) => setStudentAnswer(answer.chapterquizAnswerId)}
              checked={studentAnswer === answer.chapterquizAnswerId}
              disabled={quizSubmitted}
            />
            <label htmlFor={`answer-${answer.chapterquizAnswerId}`}>
              {answer.answerText}{' '}
              {quizSubmitted && (
                <>
                  {solutions.some(
                    (s) => s.answer === answer.chapterquizAnswerId
                  ) && <i className="check circle icon green"></i>}
                  {!solutions.some(
                    (s) => s.answer === answer.chapterquizAnswerId
                  ) &&
                    studentAnswer === answer.chapterquizAnswerId && (
                      <i className="times circle icon red"></i>
                    )}
                </>
              )}
            </label>
          </div>
        </div>
      );
    });
  };

  const numberCorrect = questions.filter((q) => {
    const solution = solutions.find(
      (s) => s.chapterquizQuestionId === q.chapterquizQuestionId
    );
    return solution && solution.answer === q.studentAnswer;
  }).length;
  const quizSubmittedMarkup = (
    <>
      {passed && (
        <>
          Congratulations, you passed the chapter quiz. <br />
          Please click Submit below to continue.
        </>
      )}
      {!passed && (
        <>
          You must get at least 4 out of 5 questions correct to pass the chapter
          quiz. <br />
          You got {numberCorrect} out of 5 questions correct. <br />
          Please click Submit below to try again.
        </>
      )}
    </>
  );

  const validationMessage = () => {
    if (questions.some((q) => !q.studentAnswer)) {
      return 'Please answer all questions.';
    }
    return '';
  };

  return (
    <>
      <div ref={topRef} />
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      {quizSubmitted && (
        <h2 className="ui center aligned header">{quizSubmittedMarkup}</h2>
      )}
      <div className="ui form">{renderQuestions()}</div>
      {showValidationInfo && attemptedSubmit && (
        <p className="error">{validationMessage()}</p>
      )}
      <br />
      <div ref={bottomRef} />
    </>
  );
};

export default CoursePageChapterquiz;
