import React, { useState, useEffect } from 'react';
import CcExpInput from './CcExpInput';
import CcImages from './CcImages';
import CcNumberInput from './CcNumberInput';
import CcSecurityCodeInput from './CcSecurityCodeInput';
import RegistrationSteps from '../RegistrationSteps';
import { useParams, useNavigate } from 'react-router-dom';
import {
  coursePayment,
  checkPromoCode,
  courseFromEnrollment,
  studentData as getStudentInfo,
} from 'dsoapi';

const Payment = ({ showRegistrationSteps, siteName, setTitle = () => {} }) => {
  const [course, setCourse] = useState('');
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [nameOnCard, setNameOnCard] = useState('');
  const [ccNum, setCcNum] = useState('');
  const [expDate, setExpDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [ccType, setCcType] = useState('');
  const [mailCert, setMailCert] = useState(false);
  const [paymentItems, setPaymentItems] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [validations, setValidations] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [ccError, setCcError] = useState('');
  const { uceId } = useParams();
  const navigate = useNavigate();

  // New state to hold student info
  const [studentInfo, setStudentInfo] = useState(null);

  const getPromoDiscountAmount = async (courseId) => {
    if (localStorage.getItem('promoCode')) {
      const promoResp = await checkPromoCode({
        promoCode: localStorage.getItem('promoCode'),
        courseId,
      });
      const { isValid, discountAmount } = promoResp.data;
      return isValid ? discountAmount : 0;
    }
    return 0;
  };

  setTitle('Payment');

  useEffect(() => {
    const fetchData = async () => {
      const courseResp = await courseFromEnrollment(uceId);
      const response = await getStudentInfo(); // Fetch student info
      setStudentInfo(response.data); // Store student info in state
      setCourse(courseResp.data);
      const { courseId } = courseResp.data;
      const promoDiscountAmount = await getPromoDiscountAmount(courseId);
      setPromoDiscount(promoDiscountAmount);
    };
    fetchData();
  }, [uceId]);

  useEffect(() => {
    let validationErrors = [];
    if (ccNum.length < 15 || ccType.length === 0) {
      validationErrors.push('valid credit card number is required');
    }
    if (!nameOnCard) {
      validationErrors.push('name on card is required');
    }
    if (expDate.length !== 7) {
      validationErrors.push('expiration month and year are required');
    }
    const now = new Date();
    const nowMonthYear = new Date(now.getFullYear(), now.getMonth(), 1);
    const [expMonth, expYear] = expDate.split('/').map((x) => x.trim());
    const expMonthYear = new Date(
      Number(`20${expYear}`),
      Number(expMonth) - 1,
      1
    );
    if (nowMonthYear > expMonthYear) {
      validationErrors.push(
        'credit card expiration date cannot be in the past'
      );
    }
    if (securityCode.length < 3) {
      validationErrors.push('security code is required');
    }
    setValidations(validationErrors);
  }, [nameOnCard, ccNum, expDate, securityCode, ccType]);

  useEffect(() => {
    const items = [];
    if (course) {
      items.push({ name: 'Course', amount: course.price });
    }
    if (mailCert) {
      items.push({ name: 'Mail Certificate', amount: 7.5 });
    }
    if (promoDiscount) {
      items.push({ name: 'Promo Discount', amount: promoDiscount });
    }
    setPaymentItems(items);
  }, [course, promoDiscount, mailCert]);

  const formatMoney = (amount) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amount);
  };

  const onPay = async () => {
    const [expMonth, expYear] = expDate.split('/').map((x) => x.trim());
    setProcessing(true);
    const request = {
      payerName: nameOnCard,
      creditCardNumber: ccNum.replace(/\s/g, ''),
      cvv: securityCode,
      expirationMonth: Number(expMonth),
      expirationYear: Number(`20${expYear}`),
      mailCert: mailCert,
      promoCode: localStorage.getItem('promoCode'),
    };
    const response = await coursePayment(uceId, request);
    localStorage.removeItem('promoCode');
    if (response.status === 200 && response.data.success) {
      if (response.data.conversionInfo) {
        const {
          conversionId,
          conversionLabel,
          amount,
          currencyCode,
          transactionId,
        } = response.data.conversionInfo;
        window.gtag('event', 'conversion', {
          send_to: `AW-${conversionId}/${conversionLabel}`,
          value: amount,
          currency: currencyCode,
          transaction_id: transactionId,
        });
      }
      navigate(`/dashboard`);
    }
    if (!response.data.success) {
      setCcError(response.data.errorReason);
    }
    setProcessing(false);
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    setCcError('');
    if (validations.length === 0) {
      setProcessing(!processing);
      await onPay();
    }
  };

  const renderValidations = () => {
    if (!showValidation) {
      return;
    }
    const renderedValidations = validations.map((v, i) => <li key={i}>{v}</li>);
    return (
      <ul style={{ textAlign: 'left', color: '#9f3a38' }}>
        {renderedValidations}
      </ul>
    );
  };

  return (
    <>
      <div className="ui center aligned container">
        {showRegistrationSteps && <RegistrationSteps step={3} />}
        <h3>{course.displayName}</h3>
      </div>
      <div className="ui container">
        <div className="ui divider"></div>
        <form className="ui form" onSubmit={onFormSubmit}>
          <div className="ui center aligned grid">
            <div className="column" style={{ maxWidth: '450px' }}>
              <CcImages ccType={ccType} />
              <div className="field">
                <label htmlFor="cardNumber" style={{ textAlign: 'left' }}>
                  Card Number
                </label>
                <CcNumberInput
                  id="cardNumber"
                  placeholder="Card Number"
                  value={ccNum}
                  onChange={setCcNum}
                  setCcType={setCcType}
                />
              </div>
              <div className="field">
                <label htmlFor="nameOnCard" style={{ textAlign: 'left' }}>
                  Name on card
                </label>
                <input
                  id="nameOnCard"
                  placeholder="Name on card"
                  onChange={(e) => setNameOnCard(e.target.value)}
                />
              </div>
              <div className="fields" style={{ paddingBottom: '15px' }}>
                <div className="eight wide field">
                  <label htmlFor="expirationDate" style={{ textAlign: 'left' }}>
                    Expiration date (MM/YY)
                  </label>
                  <CcExpInput
                    id="expirationDate"
                    placeholder="MM / YY"
                    value={expDate}
                    onChange={setExpDate}
                  />
                </div>
                <div className="eight wide field">
                  <label htmlFor="securityCode" style={{ textAlign: 'left' }}>
                    Security code
                  </label>
                  <CcSecurityCodeInput
                    id="securityCode"
                    placeholder="Security Code"
                    value={securityCode}
                    onChange={setSecurityCode}
                  />
                </div>
              </div>
              <div className="field" style={{ textAlign: 'left' }}>
                <div className="ui checkbox">
                  <input
                    type="checkbox"
                    id="mailCertCheckbox"
                    className="hidden"
                    value={mailCert}
                    onChange={(_) => setMailCert(!mailCert)}
                  />
                  <label htmlFor="mailCertCheckbox">
                    Optional: Mail Course Certificate via USPS ($7.50 fee)
                    <br />
                    <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                      To:
                      <span style={{ marginLeft: '10px' }}>
                        {studentInfo ? (
                          <>
                            {studentInfo.address}
                            <br />
                            <span style={{ marginLeft: '30px' }}>
                              {studentInfo.city}, {studentInfo.state}{' '}
                              {studentInfo.zip}
                            </span>
                          </>
                        ) : (
                          'address'
                        )}
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <ul
                style={{
                  textAlign: 'left',
                  paddingLeft: '14px',
                  marginBottom: '0px',
                }}
              >
                <li>
                  {siteName} payment receipt will be emailed to you upon
                  successful transaction
                </li>
                <li>
                  Certificate of completion will be emailed to you for FREE
                </li>
              </ul>
              <br />
              <div className="ui divider" />
              <div className="ui grid">
                <div className="eight wide left aligned column">
                  {paymentItems.length > 1 &&
                    paymentItems.map((item) => (
                      <div key={item.name}>
                        {item.name}
                        <br />
                      </div>
                    ))}
                  <strong>Total:</strong>
                </div>
                <div className="eight wide right aligned column">
                  {paymentItems.length > 1 &&
                    paymentItems.map((item) => (
                      <div key={item.amount}>
                        {formatMoney(item.amount)}
                        <br />
                      </div>
                    ))}
                  <strong>
                    {formatMoney(
                      paymentItems.reduce((acc, cur) => acc + cur.amount, 0)
                    )}
                  </strong>
                </div>
              </div>
              <div className="ui divider" />
              <input
                className="ui primary button"
                type="submit"
                value={processing ? 'Processing...' : 'Pay Now'}
                disabled={processing}
              />
              <br />
              <br />
              <p className="error">{ccError}</p>
              {renderValidations()}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Payment;
