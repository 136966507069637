import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { studentLoginGoogle } from 'dsoapi';
import UserContext from './UserContext';

const OAuthGoogle = ({ site }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams?.get('code');
  const error = searchParams?.get('error');
  const [errorMessage, setErrorMessage] = useState('');
  const { setStudentInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const doGoogleLogin = async () => {
      if (error) {
        setErrorMessage(error);
        return;
      }
      const request = {
        oAuthCode: code,
        redirectUri: `${window.location.origin}/oauth2/google`,
        site,
      };
      const response = await studentLoginGoogle(request);
      const {
        dsoapiToken,
        errorMessage: apiErrorMessage,
        studentName,
        emailAddress,
        claims,
        success,
      } = response.data;
      if (!success) {
        setErrorMessage(apiErrorMessage);
        return;
      }
      localStorage.setItem('dsoaToken', dsoapiToken);
      localStorage.setItem('dsoaClaims', JSON.stringify(claims));
      setStudentInfo({ studentName, emailAddress });
      navigate('/dashboard');
    };
    doGoogleLogin();
  }, [code, error, navigate, setStudentInfo]);

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h3>Logging in...</h3>
      </div>
      {errorMessage && (
        <>
          <br />
          <p className="error">Error logging in {errorMessage}</p>
          <Link to="/login">return to login</Link> or{' '}
          <Link to="/signup">return to signup</Link>
        </>
      )}
    </div>
  );
};

export default OAuthGoogle;
