import React, { useEffect } from 'react';
import Video from '../Video';

const CoursePageVideo = ({ courseData, setPageValid }) => {
  useEffect(() => {
    setPageValid(() => () => true);
  }, [setPageValid]);

  return (
    <>
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      <Video
        videoPath={courseData.videoPath}
        posterPath={courseData.videoPosterPath}
        videoCaptions={courseData.videoCaptions}
      />
      {courseData.coursePageHtml && (
        <>
          <div className="ui divider"></div>
          <div
            dangerouslySetInnerHTML={{ __html: courseData.coursePageHtml }}
          />
        </>
      )}
    </>
  );
};

export default CoursePageVideo;
