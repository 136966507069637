import './HomeImages.css';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const HomeImages = () => {
  return (
    <>
      <div className="homeImages desktop-only">
        <div
          className="ui stackable equal width grid"
          style={{ textAlign: 'center' }}
        >
          <div className="column">
            <img
              src="https://static-content.fedsafe.com/images/new_pc_tablet_phone_icon.svg"
              style={{ height: '75px' }}
              alt="online"
            />
            <h3>Online</h3>
            <br />
            Designed for Phones,
            <br />
            Tablets and Desktops
          </div>
          <div className="column">
            <img
              src="https://static-content.fedsafe.com/images/new_headphones_icon.svg"
              style={{ height: '75px' }}
              alt="listen"
            />
            <h3>Listen</h3>
            <br />
            Audio read along included
            <br />
            at no additional charge
          </div>
          <div className="column">
            <img
              src="https://static-content.fedsafe.com/images/new_play_icon_icon.svg"
              style={{ height: '75px' }}
              alt="go at your own pace"
            />
            <h3>Your Own Pace</h3>
            <br />
            Log in as many times as you need
            <br />
            Your progress will always be saved
          </div>
          <div className="column">
            <img
              src="https://static-content.fedsafe.com/images/new_certificate_icon.svg"
              style={{ height: '75px' }}
              alt="complete it"
            />
            <h3>Complete It!</h3>
            <br />
            Your certificate will be emailed to
            <br />
            you upon completion at no extra cost
          </div>
        </div>
      </div>
      <div
        className="mobile-and-tablet"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Carousel
            width="300px"
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            autoPlay={true}
            interval={5000}
          >
            <div>
              <br />
              <img
                src="https://static-content.fedsafe.com/images/new_pc_tablet_phone_icon.svg"
                alt="online"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <h3>Online</h3>
              <br />
              Designed for Phones,
              <br />
              Tablets and Desktops
            </div>
            <div>
              <img
                src="https://static-content.fedsafe.com/images/new_headphones_icon.svg"
                alt="listen"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <h3>Listen</h3>
              <br />
              Audio read along included
              <br />
              at no additional charge
            </div>
            <div>
              <img
                src="https://static-content.fedsafe.com/images/new_play_icon_icon.svg"
                alt="go at your own pace"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <h3>Your Own Pace</h3>
              <br />
              Log in as many times as you need
              <br />
              Your progress will always be saved
            </div>
            <div>
              <img
                src="https://static-content.fedsafe.com/images/new_certificate_icon.svg"
                alt="complete it"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <h3>Complete It!</h3>
              <br />
              Your certificate will be emailed to
              <br />
              you upon completion at no extra cost
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default HomeImages;
