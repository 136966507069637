import { courseNextGatherInfo } from 'dsoapi';

const NextGatherInfoUrl = async (uceId) => {
  const {
    data: { nextPage },
  } = await courseNextGatherInfo(uceId);
  switch (nextPage) {
    case 'Payment':
      return `/payment/${uceId}`;
    default:
      return `/gatherinfo/${nextPage}/${uceId}`;
  }
};

export default NextGatherInfoUrl;
