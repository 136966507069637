/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const RegistrationSteps = ({ step, linkSelectCourse = false }) => {
  const navigate = useNavigate();

  const stepClass = (uiStep) => {
    if (uiStep < step) {
      return 'completed';
    }
    if (uiStep === step) {
      return 'active';
    }
    return 'disabled';
  };

  const ActiveClass = (uiStep) => (uiStep === step ? 'active' : '');

  const MaybeNavigateHome = () => {
    if (linkSelectCourse) {
      navigate('/');
    }
  };

  return (
    <>
      <div className="desktop-only">
        <div className="ui three steps">
          <div className={`${stepClass(1)} step`}>
            <i className="car icon"></i>
            <div className="content">
              {linkSelectCourse ? (
                <Link to="/">
                  <div className="title" style={{ color: 'rgba(0,0,0,.87)' }}>
                    Select Course
                  </div>
                </Link>
              ) : (
                <div className="title">Select Course</div>
              )}
            </div>
          </div>
          <div className={`${stepClass(2)} step`}>
            <i className="pencil alternate icon"></i>
            <div className="content">
              <div className="title">Register</div>
            </div>
          </div>
          <div className={`${stepClass(3)} step`}>
            <i className="credit card outline icon"></i>
            <div className="content">
              <div className="title">Pay</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-and-tablet">
        <div className="ui three item menu">
          <a
            className={`item ${ActiveClass(1)}`}
            style={linkSelectCourse ? {} : { pointerEvents: 'none' }}
          >
            <div onClick={() => MaybeNavigateHome()}>
              <i className="car icon"></i>Select Course
            </div>
          </a>
          <a
            className={`item ${ActiveClass(2)}`}
            style={{ pointerEvents: 'none' }}
          >
            <i className="pencil alternate icon"></i>Register
          </a>
          <a
            className={`item ${ActiveClass(3)}`}
            style={{ pointerEvents: 'none' }}
          >
            <i className="credit card outline icon"></i>Pay
          </a>
        </div>
      </div>
    </>
  );
};

export default RegistrationSteps;
