import React from 'react';

const CcExpInput = ({ id, placeholder, value, onChange }) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0');

  const onMaybeChange = (expiry) => {
    // first number is 0 or 1
    if (value === '' && ['0', '1'].includes(expiry)) {
      onChange(expiry);
    }
    // first number is 2-9
    if (/^0?[2-9]$/.test(expiry)) {
      onChange(`0${Number(expiry)} / `);
    }
    // month is 10-12 or '01'
    if (/^(1[0-2]|01)$/.test(expiry)) {
      onChange(`${expiry} / `);
    }
    // user deleted everything
    if (expiry === '') {
      onChange(expiry);
    }
    // backspace after entering month
    if (/^\d\d \/$/.test(expiry)) {
      onChange(expiry.substring(0, 1));
    }
    // backspace after entering 1 digit of year
    if (/^\d\d \/ $/.test(expiry)) {
      onChange(expiry);
    }
    const [month, year] = expiry.split('/').map((x) => x.trim());
    if (/^\d\d?$/.test(month) && /^\d\d?$/.test(year)) {
      onChange(`${zeroPad(month, 2)} / ${year}`);
    }
    // month is 0 if user deletes month when year is there
    if (/^0?0$/.test(month) && value.includes('/')) {
      onChange('');
    }
  };

  return (
    <input
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onMaybeChange(e.target.value)}
      type="text"
      inputMode="numeric"
      pattern="^\d* ?\/? ?\d*$"
    />
  );
};

export default CcExpInput;
