import './Banner.css';
import React from 'react';

const Banner = ({ primary, secondary }) => {
  return (
    <div className="banner">
      <h1>{primary}</h1>
      <h3>{secondary}</h3>
    </div>
  );
};

export default Banner;
