import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonReEnroll from './ButtonReEnroll';

const RegisterTab = ({ courses, anyDashboardRecords }) => {
  const navigate = useNavigate();

  const renderList = () => {
    return courses.map((course) => {
      return (
        <div className="item" key={course.userCourseEnrollmentId}>
          <div className="right floated content">
            <Link to={linkPath(course)} className="ui primary button">
              Resume
            </Link>
          </div>
          <div className="header">{course.displayName}</div>
        </div>
      );
    });
  };

  const linkPath = ({ navigateTo, userCourseEnrollmentId }) => {
    switch (navigateTo) {
      case 'Payment':
        return `/payment/${userCourseEnrollmentId}`;
      default:
        return `/gatherinfo/${navigateTo}/${userCourseEnrollmentId}`;
    }
  };

  return (
    <>
      <div className="ui middle aligned divided list">{renderList()}</div>
      <br />
      <ButtonReEnroll anyDashboardRecords={anyDashboardRecords} />
      <br />
    </>
  );
};

export default RegisterTab;
