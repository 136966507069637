import React, { useEffect } from 'react';

const CoursePageText = ({ courseData, setPageValid }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageValid(() => () => true);
  }, [setPageValid]);

  const html = courseData.coursePageHtml.replace(
    'rounded-corners img-responsive',
    'ui fluid rounded image'
  );
  // uncomment once we updated all of the existing image css
  // const html = courseData.coursePageHtml.replace(
  //   new RegExp('rounded-corners img-responsive', 'g'),
  //   'ui fluid rounded image'
  // );
  return (
    <>
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};

export default CoursePageText;
