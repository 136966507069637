import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonReEnroll = ({ anyDashboardRecords }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="ui centered grid">
        <div className="row">
          <div className="column">
            <button
              className="fluid ui primary button"
              onClick={() => navigate('/')}
            >
              {anyDashboardRecords
                ? 'Click here to enroll in another course'
                : 'Click here to enroll in a course'}
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: '20px' }}></div>
    </>
  );
};

export default ButtonReEnroll;
