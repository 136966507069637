import React from 'react';
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard-old.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import visaMono from 'payment-icons/min/mono/visa.svg';
import mastercardMono from 'payment-icons/min/mono/mastercard-old.svg';
import discoverMono from 'payment-icons/min/mono/discover.svg';
import amexMono from 'payment-icons/min/mono/amex.svg';

const imgStyle = { maxWidth: '60px' };

const CcImages = ({ ccType }) => {
  const visaImg = () => {
    const imgSrc = ccType === 'Visa' ? visa : visaMono;
    return <img src={imgSrc} alt="Visa" style={imgStyle} />;
  };
  const mastercardImg = () => {
    const imgSrc = ccType === 'Mastercard' ? mastercard : mastercardMono;
    return <img src={imgSrc} alt="Mastercard" style={imgStyle} />;
  };
  const discoverImg = () => {
    const imgSrc = ccType === 'Discover' ? discover : discoverMono;
    return <img src={imgSrc} alt="Discover" style={imgStyle} />;
  };
  const amexImg = () => {
    const imgSrc = ccType === 'American Express' ? amex : amexMono;
    return <img src={imgSrc} alt="American Express" style={imgStyle} />;
  };

  return (
    <div className="ui grid">
      <div className="four wide column">{visaImg()}</div>
      <div className="four wide column">{mastercardImg()}</div>
      <div className="four wide column">{discoverImg()}</div>
      <div className="four wide column">{amexImg()}</div>
    </div>
  );
};

export default CcImages;
