import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

// example category
// {
//   "title": "Help Page Questions",
//   "pages": [
//     {
//         "title": "How do I get my car insurance discount applied?",
//         "titleSlug": "how-do-i-get-my-car-insurance-discount-applied"
//     },
//   ]
// }

const HelpPageCategory = ({ category }) => {
  const navigate = useNavigate();

  const renderPages = () => {
    return category.pages.map((page) => {
      const url = `/help/${page.titleSlug}`;
      return (
        <Table.Row
          className="clickable"
          key={page.titleSlug}
          onClick={(e) => {
            if (e.target.tagName.toLowerCase() !== 'a') {
              navigate(url);
            }
          }}
        >
          <Table.Cell>
            <Link to={url} style={{ color: 'black', fontWeight: 'normal' }}>
              {page.title}
            </Link>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Table size="large">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{category.title}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderPages()}</Table.Body>
    </Table>
  );
};

export default HelpPageCategory;
