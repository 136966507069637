import { useEffect } from 'react';

export const useDisableCopyFeatures = (disable) => {
  useEffect(() => {
    if (disable) {
      document.addEventListener('contextmenu', disableRightClick);
      document.addEventListener('keydown', disableHighlightingKeys);
      document.addEventListener('keydown', disablePrintingKeys);
      return () => {
        document.removeEventListener('contextmenu', disableRightClick);
        document.removeEventListener('keydown', disableHighlightingKeys);
        document.removeEventListener('keydown', disablePrintingKeys);
      };
    }
  }, [disable]);

  const disableRightClick = (e) => {
    e.preventDefault();
    alert('Right Click Disabled');
  };

  const disableHighlightingKeys = (e) => {
    if (
      (e.ctrlKey && e.keyCode === 65) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 65) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 65) ||
      e.keyCode === 123
    ) {
      e.preventDefault();
    }
  };

  const disablePrintingKeys = (e) => {
    if (
      (e.ctrlKey && e.keyCode === 80) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 80) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 80) ||
      (e.ctrlKey && e.shiftKey && e.keyCode === 83)
    ) {
      e.preventDefault();
    }
  };
};
