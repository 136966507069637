import _ from 'lodash';
import { parse } from 'date-fns';

export const suffixOptions = [
  {
    text: 'None',
    value: '',
  },
  {
    text: 'JR',
    value: 'JR.',
  },
  {
    text: 'SR',
    value: 'SR.',
  },
  {
    text: 'I',
    value: 'I',
  },
  {
    text: 'II',
    value: 'II',
  },
  {
    text: 'III',
    value: 'III',
  },
  {
    text: 'IV',
    value: 'IV',
  },
  {
    text: 'V',
    value: 'V',
  },
  {
    text: 'VI',
    value: 'VI',
  },
  {
    text: 'VII',
    value: 'VII',
  },
  {
    text: 'VIII',
    value: 'VIII',
  },
  {
    text: 'IX',
    value: 'IX',
  },
];

export const monthOptions = [
  {
    text: '01 - January',
    value: 1,
  },
  {
    text: '02 - February',
    value: 2,
  },
  {
    text: '03 - March',
    value: 3,
  },
  {
    text: '04 - April',
    value: 4,
  },
  {
    text: '05 - May',
    value: 5,
  },
  {
    text: '06 - June',
    value: 6,
  },
  {
    text: '07 - July',
    value: 7,
  },
  {
    text: '08 - August',
    value: 8,
  },
  {
    text: '09 - September',
    value: 9,
  },
  {
    text: '10 - October',
    value: 10,
  },
  {
    text: '11 - November',
    value: 11,
  },
  {
    text: '12 - December',
    value: 12,
  },
];

export const dayOptions = _.range(1, 32).map((x) => {
  return { text: `${x}`, value: x };
});

const yearRange = () => {
  const year = new Date().getFullYear();
  return _.range(100).map((x) => {
    return { text: `${year - 10 - x}`, value: year - 10 - x };
  });
};

export const yearOptions = yearRange();

// parse a date from the API
export const parseDate = (dateString) =>
  parse(dateString, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date());
