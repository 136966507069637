import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loggedIn } from 'dsoa-components';
import { checkPromoCode } from 'dsoapi';
import Banner from './Header/Banner';

const Promo = ({ setTitle = () => {} }) => {
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValid, setPromoCodeValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isCheckingPromoCode, setIsCheckingPromoCode] = useState(false);
  const navigate = useNavigate();

  setTitle('Promo');

  useEffect(() => {
    setShowValidation(false);
  }, [promoCode]);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    setIsCheckingPromoCode(true);
    const response = await checkPromoCode({ promoCode });
    const { isValid } = response.data;
    setPromoCodeValid(isValid);
    setShowValidation(true);
    if (isValid) {
      localStorage.setItem('promoCode', promoCode);
    } else {
      localStorage.removeItem('promoCode');
    }
    setIsCheckingPromoCode(false);
  };

  return (
    <>
      <Banner
        primary="Senior Driving Discount of America"
        secondary="A Driving School of America Company"
      />
      <div
        className="ui middle aligned center aligned grid"
        style={{ height: '100%' }}
      >
        <div className="column" style={{ maxWidth: '450px' }}>
          <h3 className="ui image header">
            <div className="content">Promo Code</div>
          </h3>
          {showValidation && !promoCodeValid && (
            <div className="ui error message">Promo code is not valid</div>
          )}
          <form className="ui large form" onSubmit={onFormSubmit}>
            <div className="ui segment">
              <div className="field">
                <div className="ui left icon input">
                  <i className="money bill alternate outline icon"></i>
                  <input
                    name="promoCode"
                    placeholder="Enter Code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                </div>
              </div>
              {!promoCodeValid && (
                <input
                  className="ui fluid large blue submit button"
                  type="submit"
                  value={isCheckingPromoCode ? 'Applying...' : 'Apply'}
                  disabled={isCheckingPromoCode || promoCodeValid}
                />
              )}
              <div style={{ height: '10px' }} />
              {promoCodeValid && (
                <>
                  <span style={{ fontWeight: 'bold' }}>
                    Promo code applied!
                  </span>{' '}
                  Your discount will be reflected on the payment screen. Please
                  select continue to choose your state and register for the
                  course at a discounted rate!
                  <br />
                  <br />
                  <button
                    className="ui fluid large blue button"
                    type="button"
                    onClick={() => navigate(loggedIn() ? '/dashboard' : '/')}
                  >
                    Continue
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Promo;
