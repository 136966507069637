import React, { useState, useEffect, useCallback } from 'react';
import { coursePageSecondsRemaining } from 'dsoapi';

const BottomMenu = ({
  coursePageType,
  pageTotalSeconds,
  pageSecondsRemaining,
  hasPreviousPage,
  hasNextPage,
  onToggleToc,
  pageHasAudio,
  setAudioPanelVisible,
  audioPlaying,
  onBack,
  onNext,
  pageIsSubmitting,
  hashLink,
}) => {
  const [timerSeconds, setTimerSeconds] = useState(0);

  const handleVisibilityChange = useCallback(async () => {
    if (document.visibilityState === 'visible' && hashLink) {
      const response = await coursePageSecondsRemaining(hashLink);
      setTimerSeconds(response.data?.secondsRemaining ?? 0);
    }
  }, [hashLink]);

  useEffect(() => {
    // check if the Page Visibility API is supported
    if ('visibilityState' in document) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      };
    }
  }, [handleVisibilityChange]);

  useEffect(() => {
    setTimerSeconds(pageSecondsRemaining);
    const intervalId = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        const newSeconds = Math.max(0, prevSeconds - 1);
        if (newSeconds === 0) {
          clearInterval(intervalId);
        }
        return newSeconds;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [pageSecondsRemaining]);

  const isNextButtonEnabled = () => timerSeconds === 0 && !pageIsSubmitting;

  const hourglassIcon = () => {
    if (timerSeconds === 0) {
      return <i className="hourglass end icon"></i>;
    } else if (timerSeconds < pageTotalSeconds / 2) {
      return <i className="hourglass half icon"></i>;
    } else {
      return <i className="hourglass start icon"></i>;
    }
  };

  const formattedTime = () => {
    const hours = parseInt(timerSeconds / 3600);
    const minutes = parseInt((timerSeconds % 3600) / 60);
    const seconds = parseInt(timerSeconds % 60);
    return timerSeconds >= 3600
      ? `${hours}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`
      : `${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
  };

  const zeroPad = (num, places) => {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  };

  const maybeOnNext = () => {
    if (timerSeconds === 0) {
      onNext();
    }
  };

  const renderNextButton = () => {
    const submitTypes = [
      'Quiz',
      'SecurityQuestions',
      'Scenario',
      'SubmitScenario',
      'SecurityQuestionADI12',
      'Miniquiz',
      'CourseEvaluationCa',
      'Chapterquiz',
    ];
    if (submitTypes.includes(coursePageType)) {
      if (pageIsSubmitting) {
        return (
          <>
            <div className="hide-mobile" style={{ paddingRight: '3px' }}>
              Submitting...
            </div>
            <i className="sync icon loading"></i>
          </>
        );
      }
      return (
        <>
          <div className="hide-mobile" style={{ paddingRight: '3px' }}>
            Submit
          </div>
          <i className="upload icon"></i>
        </>
      );
    } else if (!hasNextPage && coursePageType !== 'Init') {
      if (pageIsSubmitting) {
        return (
          <>
            <div className="hide-mobile" style={{ paddingRight: '3px' }}>
              Submitting...
            </div>
            <i className="sync icon loading"></i>
          </>
        );
      }
      return (
        <>
          <div className="hide-mobile" style={{ paddingRight: '3px' }}>
            Complete Course
          </div>
          <i className="shipping fast icon"></i>
        </>
      );
    }
    return (
      <>
        <div className="hide-mobile" style={{ paddingRight: '3px' }}>
          Next
        </div>
        <i className="arrow right icon"></i>
      </>
    );
  };

  return (
    <div className="ui bottom fixed menu">
      <div className="ui container">
        <div
          className={`item clickable${hasPreviousPage ? '' : ' disabled'}`}
          onClick={() => onBack()}
        >
          <i className="arrow left icon"></i>
          <div className="hide-mobile">Back</div>
        </div>
        <div className="item clickable" onClick={() => onToggleToc()}>
          <i className="bars icon"></i>
          <div className="hide-mobile">Table of Contents</div>
        </div>
        {pageTotalSeconds !== 0 && (
          <div
            className="borderless item"
            style={{
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ paddingRight: '3px' }}>{formattedTime()}</div>
            {hourglassIcon()}
          </div>
        )}
        <div className="right menu">
          <div
            className="item clickable"
            onClick={() => setAudioPanelVisible(true)}
          >
            {pageHasAudio && (
              <>
                <i className={`volume ${audioPlaying ? 'up' : 'off'} icon`}></i>
                <div className="hide-mobile">Audio / Progress</div>
              </>
            )}
            {!pageHasAudio && (
              <>
                <i className="ellipsis vertical icon"></i>
                <div className="hide-mobile">Progress</div>
              </>
            )}
          </div>
          <div
            className={`item clickable${
              isNextButtonEnabled() ? '' : ' disabled'
            }`}
            style={{ borderRight: '1px solid rgba(34,36,38,.1)' }}
            onClick={() => maybeOnNext()}
          >
            {renderNextButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomMenu;
