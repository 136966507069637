import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import {
  courseAddSddanjInfo,
  courseDlStatesOfRecord,
  courseEyeColors,
  studentData,
} from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const genders = [
  { text: 'Male', value: 'M' },
  { text: 'Female', value: 'F' },
];

const GatherInfoSDDANJ = ({ uceId }) => {
  const [eyes, setEyes] = useState([]);
  const [selectedEyeColor, setSelectedEyeColor] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [dlNumber, setDlNumber] = useState('');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const eyeColorsPromise = courseEyeColors();
      const statesPromise = courseDlStatesOfRecord();
      const studentDataPromise = studentData();
      const eyeColorsResp = await eyeColorsPromise;
      const statesResp = await statesPromise;
      const studentResp = await studentDataPromise;
      setEyes(eyeColorsResp.data);
      setDlNumber(studentResp.data.driverLicenseNumber);
      setStates(statesResp.data);
      setSelectedState('NJ');
    };
    fetchData();
  }, [uceId]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    if (dlNumber && selectedGender && selectedEyeColor && selectedState) {
      setSubmitting(true);
      const request = {
        driverLicenseNumber: dlNumber,
        dlStateOfRecord: selectedState,
        eyeColor: selectedEyeColor,
        gender: selectedGender,
      };
      await courseAddSddanjInfo(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setSubmitting(false);
      navigate(url);
    }
  };

  function validateDLField() {
    if (dlNumber.length < 1) {
      return {
        isValid: false,
        message: 'Driver license number is required',
      };
    }
    if (selectedState === 'FL') {
      const regex = /^[a-zA-Z][0-9]{12}$/;
      if (!regex.test(dlNumber)) {
        return {
          isValid: false,
          message:
            'Florida drivers license numbers consist of one letter and 12 numbers formatted as A123456789012',
        };
      }
    }
    if (selectedState === 'NJ') {
      const regex = /^[a-zA-Z][0-9]{14}$/;
      if (!regex.test(dlNumber)) {
        return {
          isValid: false,
          message:
            'New Jersey drivers license numbers consist of one letter and 14 numbers formatted as A12345678901234',
        };
      }
    }
    if (selectedState === 'CA') {
      const regex = /^[A-Za-z][0-9]{7}$/;
      if (!regex.test(dlNumber)) {
        return {
          isValid: false,
          message:
            'California drivers license numbers consist of one letter and 7 numbers formatted as A1234567',
        };
      }
    }
    return {
      isValid: true,
      message: '',
    };
  }

  return (
    <form className="ui form" onSubmit={onSubmit}>
      <div className="ui container">
        <h4 className="ui dividing header">Enter Information</h4>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="gender">Gender</label>
              <Dropdown
                name="gender"
                style={{ maxHeight: '27rem' }}
                placeholder="Select gender"
                fluid
                selection
                options={genders}
                value={selectedGender}
                onChange={(_, { value }) => setSelectedGender(value)}
              />
              {showValidation && !selectedGender && (
                <p className="error">gender is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="eyeColor">Eye Color</label>
              <Dropdown
                name="eyeColor"
                style={{ maxHeight: '27rem' }}
                placeholder="Select eye color"
                fluid
                selection
                options={eyes}
                value={selectedEyeColor}
                onChange={(_, { value }) => setSelectedEyeColor(value)}
              />
              {showValidation && !selectedEyeColor && (
                <p className="error">eye color is required</p>
              )}
            </div>
          </div>
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="dlStateOfRecord">
                Driver License State of Record (Where your license was issued)
              </label>
              <Dropdown
                name="dlStateOfRecord"
                style={{ maxHeight: '27rem' }}
                placeholder="Select state"
                fluid
                selection
                options={states}
                value={selectedState}
                onChange={(_, { value }) => setSelectedState(value)}
              />
              {showValidation && !selectedState && (
                <p className="error">dl state of record is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="dlNumber">Drivers License Number</label>
              <input
                name="dlNumber"
                placeholder="Drivers License Number"
                value={dlNumber}
                onChange={(e) => setDlNumber(e.target.value)}
              />
              {showValidation && !validateDLField().isValid && (
                <p className="error">{validateDLField().message}</p>
              )}
            </div>
            <div style={{ height: '27px' }} />
            <input
              className="ui primary button right floated content"
              type="submit"
              value={submitting ? 'Submitting...' : 'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GatherInfoSDDANJ;
