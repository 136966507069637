import React, { useState, useEffect } from 'react';
import { courseStates } from 'dsoapi';
import { Dropdown } from 'semantic-ui-react';

const StateDropDown = ({ placeholder, onSelectState, filterStates }) => {
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      const response = await courseStates();
      const states = response.data
        .filter((s) => (filterStates ? !['VA'].includes(s.abbreviation) : true))
        .map((s) => {
          return {
            key: s.abbreviation,
            text: s.name,
            value: s.abbreviation,
            // image: { src: s.thumbnailImageUrl },
          };
        });
      setStateOptions(states);
    };
    fetchStates();
  }, []);

  return (
    <Dropdown
      placeholder={placeholder}
      fluid
      selection
      options={stateOptions}
      onChange={(_, { value }) => onSelectState(value)}
      search
      selectOnBlur={false}
      className="state-dropdown"
    />
  );
};

export default StateDropDown;
