import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  suffixOptions,
  monthOptions,
  dayOptions,
  yearOptions,
  parseDate,
} from '../shared';
import { states, studentData, studentDataUpdate } from 'dsoapi';

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(50, 'First name is too long')
      .required('First name is required'),
    lastName: yup
      .string()
      .max(50, 'Last name is too long')
      .required('Last name is required'),
    address: yup
      .string()
      .max(50, 'Address is too long')
      .required('Address is required'),
    city: yup.string().max(50, 'City is too long').required('City is required'),
    zip: yup.string().max(10, 'Zip is too long').required('Zip is required'),
    aptNumber: yup.string().max(20, 'Apartment number is too long'),
    driverLicenseNumber: yup
      .string()
      .max(30, 'Driver license number is too long'),
    phone: yup.string().max(10, 'Phone is too long'),
  })
  .required();

const EditStudent = () => {
  const [selectedSuffix, setSelectedSuffix] = useState(suffixOptions[0].value);
  const [selectedBirthMonth, setSelectedBirthMonth] = useState(null);
  const [selectedBirthDay, setSelectedBirthDay] = useState(null);
  const [selectedBirthYear, setSelectedBirthYear] = useState(null);
  const [dobErrorMessage, setDobErrorMessage] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [stateErrorMessage, setStateErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [statusMessage, SetStatusMessage] = useState(null);
  const [updating, setUpdating] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const fetchData = async () => {
      const statesPromise = states();
      const studentDataPromise = studentData();
      const statesResp = await statesPromise;
      const studentDataResp = await studentDataPromise;
      const stOptions = statesResp.data.map((s) => {
        return { text: s.name, value: s.abbreviation };
      });
      setStateOptions(stOptions);
      const {
        firstName,
        lastName,
        suffix,
        dob,
        address,
        city,
        state,
        zip,
        aptNumber,
        driverLicenseNumber,
        phone,
        emailAddress,
      } = studentDataResp.data;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setSelectedSuffix(suffix);
      const dobDate = parseDate(dob);
      if (!(dobDate.getFullYear() === 1)) {
        setSelectedBirthYear(dobDate.getFullYear());
        setSelectedBirthMonth(dobDate.getMonth() + 1);
        setSelectedBirthDay(dobDate.getDate());
      }
      setValue('address', address);
      setValue('city', city);
      setSelectedState(state);
      setValue('zip', zip);
      setValue('aptNumber', aptNumber);
      setValue('driverLicenseNumber', driverLicenseNumber);
      setValue('phone', phone);
      setEmail(emailAddress);
    };
    fetchData();
  }, [setValue]);

  const onSubmit = async (data) => {
    if (!selectedBirthYear || !selectedBirthMonth || !selectedBirthDay) {
      setDobErrorMessage('Date of birth is required');
      return;
    } else {
      setDobErrorMessage('');
    }
    if (!selectedState) {
      setStateErrorMessage('State is required');
      return;
    } else {
      setStateErrorMessage('');
    }
    const dobDate = new Date(
      selectedBirthYear,
      selectedBirthMonth - 1,
      selectedBirthDay
    );
    const request = {
      suffix: selectedSuffix,
      state: selectedState,
      dob: dobDate.toISOString(),
      ...data,
    };
    setUpdating(true);
    await studentDataUpdate(request);
    setUpdating(false);
    SetStatusMessage('Student data updated successfully.');
  };

  return (
    <form className="ui form" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="ui dividing header">Student Information</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="field">
            <label htmlFor="firstName">First Name</label>
            <input {...register('firstName')} placeholder="First Name" />
          </div>
          <p className="error">{errors.firstName?.message}</p>
          <div className="field">
            <label htmlFor="lastName">Last Name</label>
            <input {...register('lastName')} placeholder="Last Name" />
            <p className="error">{errors.lastName?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="suffix">Suffix</label>
            <Dropdown
              name="suffix"
              style={{ maxHeight: '27rem' }}
              placeholder="None"
              fluid
              selection
              options={suffixOptions}
              value={selectedSuffix}
              onChange={(_, { value }) => setSelectedSuffix(value)}
            />
          </div>
          <div className="fields">
            <div className="six wide field">
              <label htmlFor="birthMonth">Birth Month</label>
              <Dropdown
                name="birthMonth"
                style={{ maxHeight: '27rem' }}
                placeholder="None"
                fluid
                selection
                options={monthOptions}
                value={selectedBirthMonth}
                onChange={(_, { value }) => setSelectedBirthMonth(value)}
              />
            </div>
            <div className="five wide field">
              <label htmlFor="birthDay">Birth Day</label>
              <Dropdown
                name="birthDay"
                style={{ maxHeight: '27rem' }}
                placeholder="None"
                fluid
                selection
                options={dayOptions}
                value={selectedBirthDay}
                onChange={(_, { value }) => setSelectedBirthDay(value)}
              />
            </div>
            <div className="five wide field">
              <label htmlFor="birthYear">Birth Year</label>
              <Dropdown
                name="birthYear"
                style={{ maxHeight: '27rem' }}
                placeholder="None"
                fluid
                selection
                options={yearOptions}
                value={selectedBirthYear}
                onChange={(_, { value }) => setSelectedBirthYear(value)}
              />
            </div>
          </div>
          <p className="error">{dobErrorMessage}</p>
          <div className="field">
            <label htmlFor="phone">Phone (optional)</label>
            <input {...register('phone')} placeholder="Phone" />
            <p className="error">{errors.phone?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="driverLicenseNumber">Drivers License Number</label>
            <input
              {...register('driverLicenseNumber')}
              placeholder="Drivers License Number"
            />
            <p className="error">{errors.driverLicenseNumber?.message}</p>
          </div>
        </div>
        <div className="eight wide column">
          <div className="field">
            <label htmlFor="address">Address</label>
            <input {...register('address')} placeholder="Address" />
            <p className="error">{errors.address?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="aptNumber">Apartment Number</label>
            <input {...register('aptNumber')} placeholder="Apartment Number" />
            <p className="error">{errors.aptNumber?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="city">City</label>
            <input {...register('city')} placeholder="City" />
            <p className="error">{errors.city?.message}</p>
          </div>
          <div className="field">
            <label htmlFor="state">State</label>
            <Dropdown
              name="state"
              style={{ maxHeight: '27rem' }}
              placeholder="None"
              fluid
              selection
              options={stateOptions}
              value={selectedState}
              onChange={(_, { value }) => setSelectedState(value)}
              search
            />
          </div>
          <p className="error">{stateErrorMessage}</p>
          <div className="field">
            <label htmlFor="zip">Zip</label>
            <input {...register('zip')} placeholder="Zip" />
            <p className="error">{errors.zip?.message}</p>
          </div>
          <div className="field">
            <label>Email</label>
            <p style={{ paddingTop: '10px' }}>{email}</p>
            <p className="error">{errors.zip?.message}</p>
          </div>
          <br />
          <input
            className="ui primary button"
            type="submit"
            value={updating ? 'Saving...' : 'Save'}
            disabled={updating}
          />
          <br />
          <br />
          {statusMessage}
        </div>
      </div>
    </form>
  );
};

export default EditStudent;
