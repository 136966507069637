import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { sendForgotPasswordEmail, studentResetPassword } from 'dsoapi';

const ForgotPassword = ({ site, setTitle = () => {} }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { '*': resetCode } = useParams();

  setTitle('Forgot Password');

  useEffect(() => {
    if (
      newPassword !== '' &&
      newPassword2 !== '' &&
      newPassword !== newPassword2
    ) {
      setErrorMessage("passwords don't match");
    } else {
      setErrorMessage('');
    }
  }, [newPassword, newPassword2]);

  const sendEmailSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const request = {
      emailAddress,
      site,
    };
    const result = await sendForgotPasswordEmail(request);
    if (result.data.success) {
      setSuccessMessage(
        `a password reset link has been sent to ${emailAddress}`
      );
    } else {
      setErrorMessage(result.data.reason);
    }
    setSubmitting(false);
  };

  const resetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (
      newPassword.length < 8 ||
      newPassword2.length < 8 ||
      newPassword.length > 100 ||
      newPassword2.length > 100
    ) {
      setErrorMessage('password must be between 8 and 100 characters');
      return;
    }
    if (newPassword !== newPassword2) {
      setErrorMessage("passwords don't match");
      return;
    }
    if (!newPassword.match(/^(?=.*\d)(?=.*\W)[\w\W]+$/)) {
      setErrorMessage(
        'Password must contain at least one number and one special character'
      );
      return;
    }
    setSubmitting(true);
    const result = await studentResetPassword({ id: resetCode, newPassword });
    if (!result.data.success) {
      setErrorMessage(result.data.reason);
    } else {
      setResetSuccess(true);
    }
    setSubmitting(false);
  };

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h3>Forgot Password</h3>
      </div>
      <div className="ui stackable grid">
        <div className="four wide column"></div>
        <div className="eight wide column">
          {!resetCode && !successMessage && (
            <form className="ui form" onSubmit={sendEmailSubmit}>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input
                  placeholder="Enter Email"
                  id="email"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
                <p>
                  Enter your email address to recieve a password reset link.
                </p>
              </div>
              <p className="error">{errorMessage}</p>
              <input
                className="ui primary button"
                type="submit"
                value="Send"
                disabled={submitting}
              />
            </form>
          )}
          {successMessage && (
            <>
              <br />
              <div style={{ textAlign: 'center' }}>{successMessage}</div>
            </>
          )}
          {resetCode && !resetSuccess && (
            <form className="ui form" onSubmit={resetPasswordSubmit}>
              <div className="field">
                <label htmlFor="newpassword">
                  Enter new password{' '}
                  <span style={{ opacity: '.5', fontWeight: 'normal' }}>
                    - at least 8 characters with a number and symbol
                  </span>
                </label>
                <input
                  placeholder="New password"
                  id="newpassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="reenternewpassword">
                  Re-enter new password
                </label>
                <input
                  placeholder="Re-enter new password"
                  id="reenternewpassword"
                  type="password"
                  value={newPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </div>
              <p className="error">{errorMessage}</p>
              <input
                className="ui primary button"
                type="submit"
                value="Update Password"
                disabled={submitting}
              />
            </form>
          )}
          {resetSuccess && (
            <>
              <br />
              <div style={{ textAlign: 'center' }}>
                Your password has been reset. Please{' '}
                <Link to="/login">login</Link>.
              </div>
            </>
          )}
        </div>
        <div className="four wide column"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
