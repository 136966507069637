import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { coursePageQuizReviewSddaNj } from 'dsoapi';

const CoursePageQuizReviewSddaNj = ({ courseData, setPageValid }) => {
  const { canReAttempt, passed, score, passingScore, quiz } =
    courseData.quizReview;

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageValid(() => async () => {
      if (!passed) {
        const response = await coursePageQuizReviewSddaNj(courseData.hashLink);
        if (response.data.nextHashLink) {
          navigate(`/courseapp/${response.data.nextHashLink}`);
          return false;
        } else {
          return true;
        }
      }
      return true;
    });
  }, [courseData.hashLink, setPageValid, navigate, passed]);

  let quizResultMarkup = '';
  if (passed) {
    quizResultMarkup = (
      <>
        {`Congratulations! You passed with a score of ${score}%`} <br /> Please
        click Complete Course below!
      </>
    );
  }
  if (!passed && canReAttempt) {
    quizResultMarkup = (
      <>
        {`You scored a ${score}%`}
        <br />{' '}
        {`The state requires a passing score of ${passingScore}% or more`}
        <br /> Please click Next below to attempt a new exam
      </>
    );
  }
  if (!passed && !canReAttempt) {
    quizResultMarkup = (
      <>
        {`You scored a ${score}% on attempt 3 of 3`} <br />
        {`The state requires a passing score of ${passingScore}% or more`}{' '}
        <br />
        Due to state guidelines after a third failed attempt you can re-enroll
        in New Jersey Mature Defensive Driving School.
        <br />
        Please click the next button below if you would like to re-enroll in
        Mature Defensive Driving School with Driving School of America, there is
        no Fee.
      </>
    );
  }

  const renderQuestions = () => {
    return quiz.questions.map((question, i) => {
      return (
        <div className="grouped fields" key={i}>
          <label>{question.question.questionText}</label>
          {question.question.imagePath && (
            <>
              <br />
              <img
                src={question.question.imagePath}
                alt={`question ${i}`}
                className="ui fluid image"
                style={{ maxWidth: '400px' }}
              />
            </>
          )}
          {renderAnswers(question.answers, question.question.selectedAnswerId)}
          <br />
          <p style={{ fontStyle: 'italic' }}>
            <i
              className="thumbs up outline icon"
              style={{ color: '#0281B1' }}
            />{' '}
            {question.question.answerText}
          </p>
          <br />
        </div>
      );
    });
  };

  const renderAnswers = (answers, studentAnswer) => {
    return answers.map((answer) => {
      return (
        <div className="field" key={answer.questionAnswerId}>
          <p>
            {answer.correct && (
              <i className="check icon" style={{ color: 'green' }} />
            )}
            {studentAnswer === answer.questionAnswerId && !answer.correct && (
              <i className="close icon" style={{ color: 'red' }} />
            )}
            {answer.answerText}
          </p>
        </div>
      );
    });
  };

  return (
    <>
      <h3 className="ui center aligned header">Exam Review</h3>
      <div className="ui divider"></div>
      <h3 className="ui center aligned header">{quizResultMarkup}</h3>
      {quiz.questions && (
        <>
          <h4 className="ui dividing header">Incorrect Questions</h4>
          <div className="ui form">{renderQuestions()}</div>
        </>
      )}
      <br />
    </>
  );
};

export default CoursePageQuizReviewSddaNj;
