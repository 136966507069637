import React from 'react';
import creditCardType from 'credit-card-type';

const CcNumberInput = ({ id, placeholder, value, onChange, setCcType }) => {
  const onMaybeChange = (num) => {
    const re = /^\d+$/;
    const numNoSpace = num.replaceAll(' ', '');
    if ((num === '' || re.test(numNoSpace)) && numNoSpace.length <= 19) {
      const ccType = getCcType(numNoSpace);
      if (ccType && numNoSpace.length <= Math.max(...ccType.lengths)) {
        const withSpaces = Array.from(numNoSpace).reduce(
          (acc, cur, i) =>
            ccType.gaps.includes(i) ? acc + ' ' + cur : acc + cur,
          ''
        );
        setCcType(ccType.niceType);
        onChange(withSpaces);
      } else {
        onChange(num);
        setCcType('');
      }
    }
  };

  const getCcType = (ccNum) => {
    const validCcTypes = ['visa', 'mastercard', 'american-express', 'discover'];
    const ccTypes = creditCardType(ccNum);
    if (
      ccTypes &&
      ccTypes.length === 1 &&
      validCcTypes.includes(ccTypes[0].type)
    ) {
      return ccTypes[0];
    }
    return null;
  };

  return (
    <input
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onMaybeChange(e.target.value)}
      type="text"
      inputMode="numeric"
      pattern="^(\d*| *)*$"
    />
  );
};

export default CcNumberInput;
