/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import UpdatePassword from './UpdatePassword';
import EditStudent from './EditStudent';

const EDIT_STUDENT = 'EDIT_STUDENT';
const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

const EditProfile = ({ setTitle = () => {} }) => {
  const [activeTab, setActiveTab] = useState(EDIT_STUDENT);

  setTitle('Edit Profile');

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h3>Edit Profile</h3>
      </div>
      <div style={{ height: '15px' }} />
      <div className="ui two item menu">
        <a
          className={`item${activeTab === EDIT_STUDENT ? ' active' : ''}`}
          onClick={() => setActiveTab(EDIT_STUDENT)}
        >
          Student
        </a>
        <a
          className={`item${activeTab === UPDATE_PASSWORD ? ' active' : ''}`}
          onClick={() => setActiveTab(UPDATE_PASSWORD)}
        >
          Password
        </a>
      </div>
      {activeTab === EDIT_STUDENT && <EditStudent />}
      {activeTab === UPDATE_PASSWORD && <UpdatePassword />}
    </div>
  );
};

export default EditProfile;
