import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { courseAddTlsaeInfo } from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const genders = [
  { text: 'Male', value: 'M' },
  { text: 'Female', value: 'F' },
];

const GatherInfoTLSAE = ({ uceId }) => {
  const [ssnLast_4, setSsnLast_4] = useState('');
  const [alienNumber, setAlienNumber] = useState('');
  const [nonAlienNumber, setNonAlienNumber] = useState('');
  const [selectedGender, setSelectedGender] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    if (ssnAlienPopulated() && selectedGender) {
      setSubmitting(true);
      const request = {
        ssnLast_4,
        alienNumber,
        nonAlienNumber,
        selectedGender,
        gender: selectedGender,
      };
      await courseAddTlsaeInfo(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setSubmitting(false);
      navigate(url);
    }
  };

  const ssnAlienPopulated = () => ssnLast_4 || alienNumber || nonAlienNumber;

  return (
    <form className="ui form" onSubmit={onSubmit}>
      <div className="ui container">
        <h4 className="ui dividing header">Enter TLSAE Information</h4>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="gender">Gender</label>
              <Dropdown
                name="gender"
                style={{ maxHeight: '27rem' }}
                placeholder="Select gender"
                fluid
                selection
                options={genders}
                value={selectedGender}
                onChange={(_, { value }) => setSelectedGender(value)}
              />
              {showValidation && !selectedGender && (
                <p className="error">gender is required</p>
              )}
            </div>
            <h5 className="ui dividing header">
              Use must enter one of the following:
            </h5>
            <div className="field">
              <label htmlFor="ssnLast_4">
                Last 4 digits of Social Security Number
              </label>
              <input
                name="ssnLast_4"
                placeholder="SSN Last 4 Number"
                value={ssnLast_4}
                onChange={(e) => setSsnLast_4(e.target.value)}
              />
              {showValidation && !ssnAlienPopulated() && (
                <p className="error">
                  last 4 digits of Social Security number is required
                </p>
              )}
              {showValidation && ssnLast_4.length !== 4 && (
                <p className="error">
                  Last 4 Social Security number must be 4 digits
                </p>
              )}
            </div>
            <div className="field">
              <label htmlFor="alienNumber">Alien Number</label>
              <input
                name="alienNumber"
                placeholder="Alien Number"
                value={alienNumber}
                onChange={(e) => setAlienNumber(e.target.value)}
              />
              {showValidation && !ssnAlienPopulated() && (
                <p className="error">Alien Number is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="nonAlienNumber">Non Alien Number</label>
              <input
                name="nonAlienNumber"
                placeholder="Non Alien Number"
                value={nonAlienNumber}
                onChange={(e) => setNonAlienNumber(e.target.value)}
              />
              {showValidation && !ssnAlienPopulated() && (
                <p className="error">Non Alien Nnumber is required</p>
              )}
            </div>
            <input
              className="ui primary button"
              type="submit"
              value={submitting ? 'Submitting...' : 'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GatherInfoTLSAE;
