import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import {
  courseAddTvscaInfo,
  courseCaCounties,
  courseCaCourthouses,
  courseCaLawEnforcementAgencies,
  studentData,
} from 'dsoapi';
import NextGatherInfoUrl from './NextGatherInfoUrl';

const GatherInfoTVSCA = ({ uceId }) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [courtCodes, setCourtCodes] = useState([]);
  const [lawEnforcementAgencies, setLawEnforcementAgencies] = useState([]);
  const [filteredCourtCodes, setFilteredCourtCodes] = useState([]);
  const [selectedCourtCode, setSelectedCourtCode] = useState(null);
  const [selectedLawEnforcementAgency, setSelectedLawEnforcementAgency] =
    useState(null);
  const [dlNumber, setDlNumber] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const caCountiesPromise = courseCaCounties();
      const caCourthousesPromise = courseCaCourthouses();
      const caLawEnforcementPromise = courseCaLawEnforcementAgencies();
      const studentDataPromise = studentData();
      const caCountiesResp = await caCountiesPromise;
      const caCourthousesResp = await caCourthousesPromise;
      const caLawEnforcementResp = await caLawEnforcementPromise;
      const studentResp = await studentDataPromise;
      setCounties(caCountiesResp.data);
      setCourtCodes(caCourthousesResp.data);
      setLawEnforcementAgencies(
        caLawEnforcementResp.data.map((l) => ({
          key: l.code,
          text: l.name,
          value: l.code,
        }))
      );
      setDlNumber(studentResp.data.driverLicenseNumber);
    };
    fetchData();
  }, [uceId]);

  useEffect(() => {
    if (selectedCounty) {
      const { courthouses } = courtCodes.find(
        (cc) => cc.county === selectedCounty
      );
      const chTextValue = courthouses.map((ch) => ({
        key: `${ch.name}-${ch.code}`,
        text: ch.name,
        value: ch.code,
      }));
      setFilteredCourtCodes(chTextValue);
    }
  }, [selectedCounty, courtCodes]);

  // The Los Angeles County Metropolitan Court (court code 19463) requires the
  // entered “Reference Number” in the student completion record sent to their
  // court to include the citation number and the Law Enforcement Agency (LEA)
  // code.  A citation number with the LEA code may look like this: CCCCCCCCLLLL

  const onSubmit = async (event) => {
    event.preventDefault();
    setShowValidation(true);
    const isValid =
      referenceNumber &&
      referenceNumber.length <= 20 &&
      selectedCourtCode &&
      dlNumber.match(/^[A-Za-z][0-9]{7}$/) &&
      (selectedCourtCode !== '19463' || selectedLawEnforcementAgency);
    if (isValid) {
      setSubmitting(true);
      const request = {
        referenceNumber: selectedLawEnforcementAgency
          ? `${referenceNumber}${selectedLawEnforcementAgency}`
          : referenceNumber,
        courtCode: selectedCourtCode,
        driverLicenseNumber: dlNumber,
      };
      await courseAddTvscaInfo(uceId, request);
      const url = await NextGatherInfoUrl(uceId);
      setSubmitting(false);
      navigate(url);
    }
  };

  return (
    <form className="ui form" onSubmit={onSubmit}>
      <div className="ui container">
        <h4 className="ui dividing header">Enter Information</h4>
        <div className="ui stackable grid">
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="countyReceived">County Received</label>
              <Dropdown
                name="countyReceived"
                style={{ maxHeight: '27rem' }}
                placeholder="Select county received"
                fluid
                selection
                options={counties}
                value={selectedCounty}
                onChange={(_, { value }) => {
                  setSelectedCounty(value);
                  setSelectedCourtCode(null);
                  setSelectedLawEnforcementAgency(null);
                }}
              />
              {showValidation && !selectedCounty && (
                <p className="error">County Received is required</p>
              )}
            </div>
            <div className="field">
              <label htmlFor="courtCode">Courthouse</label>
              <Dropdown
                name="courtCode"
                style={{ maxHeight: '27rem' }}
                placeholder="Select Courthouse"
                fluid
                selection
                options={filteredCourtCodes}
                value={selectedCourtCode}
                onChange={(_, { value }) => setSelectedCourtCode(value)}
                disabled={selectedCounty === null}
              />
              {showValidation && !selectedCourtCode && (
                <p className="error">Courthouse is required</p>
              )}
            </div>
            {selectedCourtCode === '19463' && (
              <div className="field">
                <label htmlFor="lawEnforcementAgency">
                  Law Enforcement Agency
                </label>
                <Dropdown
                  name="lawEnforcementAgency"
                  style={{ maxHeight: '27rem' }}
                  placeholder="Select Law Enforcement Agency"
                  fluid
                  selection
                  options={lawEnforcementAgencies}
                  value={selectedLawEnforcementAgency}
                  onChange={(_, { value }) =>
                    setSelectedLawEnforcementAgency(value)
                  }
                  disabled={selectedCourtCode !== '19463'} // Los Angeles Central District Metropolitan
                />
                {showValidation && !selectedLawEnforcementAgency && (
                  <p className="error">Law Enforcement Agency is required</p>
                )}
              </div>
            )}
          </div>
          <div className="eight wide column">
            <div className="field">
              <label htmlFor="dlNumber">Drivers License Number</label>
              <input
                name="dlNumber"
                placeholder="Drivers License Number"
                value={dlNumber}
                onChange={(e) => setDlNumber(e.target.value)}
              />
              {showValidation && !dlNumber && (
                <p className="error">Driver license number is required</p>
              )}
              {showValidation && !dlNumber.match(/^[A-Za-z][0-9]{7}$/) && (
                <p className="error">
                  Drivers License Number must be a CA format "A#######"
                </p>
              )}
            </div>
            <div className="field">
              <label htmlFor="referenceNumber">
                Citation / Docket / Ticket Number
              </label>
              <input
                name="referenceNumber"
                placeholder="Citation / Docket / Ticket Number"
                value={referenceNumber}
                onChange={(e) => setReferenceNumber(e.target.value)}
              />
              {showValidation && !referenceNumber && (
                <p className="error">Reference number is required</p>
              )}
              {showValidation && referenceNumber.length > 20 && (
                <p className="error">
                  Reference Number must be few than 20 characters
                </p>
              )}
            </div>
            <div style={{ height: '27px' }} />
            <input
              className="ui primary button right floated content"
              type="submit"
              value={submitting ? 'Submitting...' : 'Submit'}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GatherInfoTVSCA;
