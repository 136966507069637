import React, { useState, useEffect, useRef } from 'react';
import { coursePageGradeQuiz } from 'dsoapi';

const CoursePageQuiz = ({ courseData, setPageValid, showValidationInfo }) => {
  const [questions, setQuestions] = useState([]);
  const [didNotPassMessage, setDidNotPassMessage] = useState(<></>);

  const bottomRef = useRef(null);
  const topRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageValid(() => async () => {
      if (courseData.quiz.complete) {
        return false;
      }
      if (
        questions.length === 0 ||
        questions.some((q) => q.studentAnswer === null)
      ) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        return false;
      }
      const request = {
        studentAnswers: questions.map((q) => {
          return {
            userQuizId: q.question.userQuizId,
            questionAnswerId: q.studentAnswer,
          };
        }),
      };
      const response = await coursePageGradeQuiz(courseData.hashLink, request);
      if (!response.data.passed) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        setDidNotPassMessage(
          <>
            You scored {response.data.score}%. <br />A{' '}
            {response.data.quizMinimumPercent}% is required to pass.
            <br /> Please try again.
          </>
        );
        return false;
      }
      return true;
    });
  }, [questions, setPageValid, courseData.hashLink, courseData.quiz.complete]);

  useEffect(() => {
    const questionsWithAnswers = courseData.quiz.questions.map((question) => {
      return {
        ...question,
        studentAnswer: null,
      };
    });
    setQuestions(questionsWithAnswers);
  }, [courseData]);

  const setStudentAnswer = (questionIndex, questionAnswerId) => {
    const questionsCopy = [...questions];
    const questionWithAnswer = {
      ...questionsCopy[questionIndex],
      studentAnswer: questionAnswerId,
    };
    questionsCopy[questionIndex] = questionWithAnswer;
    setQuestions(questionsCopy);
  };

  const renderQuestions = () => {
    return questions.map((question, i) => {
      return (
        <div className="grouped fields" key={i}>
          <label>
            {showValidationInfo && question.studentAnswer === null && (
              <span className="error">* </span>
            )}
            {`${i + 1}. ${question.question.questionText}`}
          </label>
          {question.question.imagePath && (
            <>
              <br />
              <img
                src={question.question.imagePath}
                alt={`question ${i}`}
                className="ui image"
                style={{ maxWidth: '400px' }}
              />
            </>
          )}
          {renderAnswers(question.answers, i, question.studentAnswer)}
          <br />
        </div>
      );
    });
  };

  const renderAnswers = (answers, questionIndex, studentAnswer) => {
    return answers.map((answer) => {
      return (
        <div className="field" key={answer.questionAnswerId}>
          <div className="ui radio checkbox">
            <input
              className="hidden"
              type="radio"
              name={`question-${questionIndex}`}
              id={`answer-${answer.questionAnswerId}`}
              onChange={(_) =>
                setStudentAnswer(questionIndex, answer.questionAnswerId)
              }
              checked={studentAnswer === answer.questionAnswerId}
            />
            <label htmlFor={`answer-${answer.questionAnswerId}`}>
              {answer.answerText}
            </label>
          </div>
        </div>
      );
    });
  };

  const validationMessage = () => {
    const questionsWithoutAnswers = questions
      .map((q, i) => {
        return { q, i };
      })
      .filter((x) => x.q.studentAnswer === null)
      .map((x) => x.i + 1)
      .join(', ');
    return `Please answer the following questions: ${questionsWithoutAnswers}`;
  };

  const shouldShowValidationInfo = () =>
    showValidationInfo && questions.some((q) => q.studentAnswer === null);

  return (
    <>
      <div ref={topRef} />
      <h3 className="ui center aligned header">{courseData.title}</h3>
      <div className="ui divider"></div>
      <h2 className="ui center aligned header">{didNotPassMessage}</h2>
      <div className="ui form">{renderQuestions()}</div>
      {shouldShowValidationInfo() && (
        <p className="error">{validationMessage()}</p>
      )}
      <div ref={bottomRef} />
      <br />
    </>
  );
};

export default CoursePageQuiz;
