import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext, { isAdmin } from './UserContext';
import * as yup from 'yup';
import { studentLoginAdmin, studentEmailExists } from 'dsoapi';

const LoginAsStudent = ({ setTitle = () => {} }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [debouncedEmail, setDebouncedEmail] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const initialEmailSet = useRef(false);
  const { setStudentInfo, claims } = useContext(UserContext);
  const navigate = useNavigate();

  setTitle('Login as Student');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const autoLogin = queryParams.get('autoLogin');

  useEffect(() => {
    if (email && !initialEmailSet.current) {
      setEmailAddress(email);
      initialEmailSet.current = true;
    }
  }, [email]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedEmail(emailAddress);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [emailAddress]);

  useEffect(() => {
    if (debouncedEmail && yup.string().email().isValidSync(debouncedEmail)) {
      const checkEmail = async () => {
        const response = await studentEmailExists(debouncedEmail);
        setEmailExists(response.data.exists);
        setShowError(true);
      };
      checkEmail();
    }
  }, [debouncedEmail]);

  useEffect(() => {
    if (claims && !isAdmin(claims)) {
      navigate('/');
    }
  }, [claims, navigate]);

  const maybeAutoLogin = async () => {
    if (emailAddress && autoLogin) {
      const response = await studentEmailExists(debouncedEmail);
      if (response.data.exists) {
        loginAsStudent();
      }
    }
  };

  maybeAutoLogin();

  const loginAsStudent = () => {
    if (emailExists) {
      const doLoginAsStudent = async () => {
        setSubmitting(true);
        const response = await studentLoginAdmin({
          emailAddress: debouncedEmail,
        });
        if (response && response.status === 200 && response.data.success) {
          localStorage.setItem('dsoaToken', response.data.dsoapiToken);
          localStorage.setItem(
            'dsoaClaims',
            JSON.stringify(response.data.claims)
          );
          setStudentInfo(response.data);
          setSubmitting(false);
          navigate('/dashboard');
        } else {
          setSubmitting(false);
        }
      };
      doLoginAsStudent();
    }
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    loginAsStudent();
  };

  return (
    <div
      className="ui middle aligned center aligned grid"
      style={{ height: '100%' }}
    >
      <div className="column" style={{ maxWidth: '450px' }}>
        <h3 className="ui image header">
          <div className="content">Log in as Student</div>
        </h3>
        <form className="ui large form" onSubmit={onFormSubmit}>
          <div className="field">
            <input
              name="emailAddress"
              placeholder="Student Email Address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          {debouncedEmail && showError && !emailExists && (
            <p className="error">Email does not exist.</p>
          )}
          <input
            className="ui fluid primary button"
            type="submit"
            value={submitting ? 'Logging In...' : 'Log In'}
            disabled={submitting || !emailExists}
          />
        </form>
      </div>
    </div>
  );
};

export default LoginAsStudent;
