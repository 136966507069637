import React, { useState, useEffect } from 'react';
import { courseByAbbreviation, stateByAbbreviation } from 'dsoapi';

const SDDA = ({ stateAbbreviation, setCourseAbbreviation, setIsValid }) => {
  const [price, setPrice] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setCourseAbbreviation('SDDA');
    setIsValid(true);
  }, [setCourseAbbreviation, setIsValid]);

  useEffect(() => {
    const fetchData = async () => {
      if (stateAbbreviation) {
        const [courseResponse, stateResponse] = await Promise.all([
          courseByAbbreviation('SDDA'),
          stateByAbbreviation(stateAbbreviation),
        ]);
        const responsePrice = courseResponse.data.price;
        setPrice(`$${parseFloat(responsePrice).toFixed(2)}`);
        setImageUrl(stateResponse.data.imageUrl);
      }
    };
    fetchData();
  }, [price, imageUrl, stateAbbreviation]);

  return (
    <>
      <div
        style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}
      >
        Senior Driving Discount of America Mature Driver Course <br />
        <span style={{ fontWeight: 'bold' }}> {price}</span> Best Price Online
      </div>
      <br />
      <p>
        The Senior Driving Discount course is a mature driver insurance discount
        program available to senior drivers. It can reduce the amount you pay
        for your auto insurance. More than two-thirds of states mandate auto
        insurance policy discounts for such courses, and many insurance
        companies offer the discounts voluntarily. The successful completion of
        a defensive driving course shows a vehicle insurance company that the
        driver is skilled in avoiding accidents and is less of a liability on
        the road. Once the course is completed, a certificate of completion will
        immediately be sent to the email address you registered with for free!
        You will always have a digital copy to download through your account.
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>
              Insurance companies will typically provide up to a 15% discount on
              your car insurance
            </li>
            <li>
              Discount and certificate for the Mature Driver Improvement Course
              are good for 3 years
            </li>
            <li>
              Saving the average individual up to $500 on their car insurance
            </li>
            <li>
              Customers can reach a state-certified instructor anytime day or
              night
            </li>
            <li>
              Our course can be taken at your leisure, 24 hours a day, 7 days a
              week, via PC, Mobile Device, or Tablet.
            </li>
            <li>
              Start and resume on your own time from any device anywhere in the
              world!
            </li>
            <li>Award Winning! Guaranteed The Best Price Online!</li>
            <li>Final price, No hidden fees!</li>
          </ul>
        </div>
        <div className="six wide column">
          {imageUrl && (
            <img
              src={imageUrl}
              className="ui image"
              style={{ margin: '0 auto', maxHeight: '300px' }}
              alt="usa"
            ></img>
          )}
        </div>
      </div>

      <p style={{ fontWeight: 'bold' }}>
        Currently, over 34 states require insurance discounts for drivers 50 and
        over who complete this mature driver safety improvement course. The
        following states that mandate a discount for safe-driving classes are:
        Alabama, Alaska, Arkansas, California, Colorado, Connecticut, Delaware,
        Florida, Georgia, Idaho, Illinois, Kansas, Kentucky, Louisiana, Maine,
        Minnesota, Mississippi, Montana, Nevada, New Jersey, New Mexico, New
        York, North Dakota, Oklahoma, Oregon, Pennsylvania, Rhode Island, South
        Carolina, Tennessee, Utah, Virginia, Washington, West Virginia and
        Wyoming.
      </p>

      <p style={{ fontWeight: 'bold' }}>
        Many insurance providers give a discount for completing this course even
        though state law does not require a discount. Please consult your
        insurance company to confirm whether or not you will be eligible for a
        discount upon successful completion of our online course.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        *To avoid any confusion, you, the insured, should check with your
        insurance company or broker to ascertain if the discount will be
        applicable to your insurance premium. Registering for the course and the
        payment thereof, is a representation that you have ascertained your
        eligibility for the discount and are satisfied with the results of your
        inquiries.
      </p>
    </>
  );
};

export default SDDA;
