import React, { useState, useEffect } from 'react';
import { courseByAbbreviation } from 'dsoapi';

const SDDACA = ({ courseAbbreviation, setCourseAbbreviation, setIsValid }) => {
  const [caPrice, setCaPrice] = useState('');
  const [carPrice, setCarPrice] = useState('');

  useEffect(() => {
    setIsValid(['SDDACA', 'SDDACAR'].includes(courseAbbreviation));
  }, [setIsValid, courseAbbreviation]);

  useEffect(() => {
    const fetchData = async () => {
      const [caResponse, carResponse] = await Promise.all([
        courseByAbbreviation('SDDACA'),
        courseByAbbreviation('SDDACAR'),
      ]);
      const caResponsePrice = caResponse.data.price;
      const carResponsePrice = carResponse.data.price;
      setCaPrice(`$${parseFloat(caResponsePrice).toFixed(2)}`);
      setCarPrice(`$${parseFloat(carResponsePrice).toFixed(2)}`);
    };
    fetchData();
  }, [caPrice, carPrice]);

  return (
    <>
      <p style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}>
        Senior Driving Discount of America Mature Driver Improvement Course
        <br />
        <span style={{ fontWeight: 'bold' }}>{caPrice} </span>
        Initial Course (6 Hour)
        <br />
        <span style={{ fontWeight: 'bold' }}>{carPrice} </span>
        Refresher Course (4 Hour)
      </p>
      <p>
        The Senior Driving Discount of America Mature Driver Improvement Course
        is specifically designed for drivers 50 years of age and older and is
        100% online and lasts approximately 6 hours for the Initial and 4 hours
        for the Refresher mandated by the state of California DMV. Once the
        course is completed, a certificate of completion will immediately be
        sent to the email address you registered with for free! You will always
        have a digital copy to download through your account.
      </p>
      <p>
        California State Law mandates that completion of this course will reduce
        the amount you pay for your auto insurance. The successful completion of
        this defensive driving course shows a vehicle insurance company that the
        driver is skilled in avoiding accidents and is less of a liability on
        the road.
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>
              Insurance companies will typically provide up to a 15% discount on
              your car insurance
            </li>
            <li>
              Discount and certificate for the Mature Driver Improvement Course
              are good for 3 years
            </li>
            <li>
              Saving the average individual up to $500 on their car insurance
            </li>
            <li>
              Customers can reach a state-certified instructor anytime day or
              night
            </li>
            <li>
              Our course can be taken at your leisure, 24 hours a day, 7 days a
              week, via PC, Mobile Device, or Tablet.{' '}
            </li>
            <li>
              Start and resume on your own time from any device anywhere in the
              world!
            </li>
            <li>Award Winning! Guaranteed The Best Price Online!</li>
            <li>California DMV Licensed Course</li>
            <li>Final price, No hidden fees!</li>
          </ul>
        </div>
        <div className="six wide column">
          <img
            src="https://static-content.fedsafe.com/images/stateseals/ca.png"
            className="ui image"
            style={{ margin: '0 auto', maxHeight: '300px' }}
            alt="ca"
          ></img>
          <div style={{ height: '30px' }}></div>
        </div>
      </div>
      <p style={{ fontWeight: 'bold' }}>
        Please Select The Course For You Below:
      </p>
      <div style={{ margin: '15px' }} className="ui radio checkbox">
        <input
          id="sddacaRadio"
          type="radio"
          value="SDDACA"
          checked={courseAbbreviation === 'SDDACA'}
          onChange={(e) => setCourseAbbreviation(e.target.value)}
        />
        <label
          htmlFor="sddacaRadio"
          style={{ display: 'block', fontSize: 'large' }}
        >
          <span style={{ fontWeight: 'bold' }}>{caPrice} </span>
          Initial Course (6 Hour)
        </label>
      </div>
      <div
        style={{ marginLeft: '15px', marginBottom: '15px' }}
        className="ui radio checkbox"
      >
        <input
          id="sddacarRadio"
          type="radio"
          value="SDDACAR"
          checked={courseAbbreviation === 'SDDACAR'}
          onChange={(e) => setCourseAbbreviation(e.target.value)}
        />
        <label
          htmlFor="sddacarRadio"
          style={{ display: 'block', fontSize: 'large' }}
        >
          <span style={{ fontWeight: 'bold' }}>{carPrice} </span>
          Refresher Course (4 Hour)
        </label>
      </div>
    </>
  );
};

export default SDDACA;
