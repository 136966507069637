import React from 'react';

const UserContext = React.createContext({
  studentName: '',
  emailAddress: '',
  setStudentInfo: (info) => {},
});

export default UserContext;

export const loggedIn = () => localStorage.getItem('dsoaToken') !== null;

export const isAdmin = () => {
  const claims = JSON.parse(localStorage.getItem('dsoaClaims') ?? '{}');
  return (
    claims &&
    claims.hasOwnProperty('ADMIN') &&
    claims.ADMIN.toLowerCase() === 'true'
  );
};
