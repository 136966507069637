import React, { useState, useEffect } from 'react';
import { courseByAbbreviation } from 'dsoapi';

const SDDANJ = ({ setCourseAbbreviation, setIsValid }) => {
  const [price, setPrice] = useState('');

  useEffect(() => {
    setCourseAbbreviation('SDDANJ');
    setIsValid(true);
  }, [setCourseAbbreviation, setIsValid]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await courseByAbbreviation('SDDANJ');
      const responsePrice = response.data.price;
      setPrice(`$${parseFloat(responsePrice).toFixed(2)}`);
    };
    fetchData();
  }, [price]);

  return (
    <>
      <p style={{ fontSize: 'x-large', textAlign: 'center', lineHeight: 1.4 }}>
        New Jersey Mature Defensive Driver Course
        <br />
        <span style={{ fontWeight: 'bold' }}>{price}</span> Best Price Online
      </p>
      <p>
        By completing our 6 Hour New Jersey Motor Vehicle Commission (MVC)
        approved Mature Defensive Driver Course drivers can benefit from a
        reduction of two points from their driving record, which is mandated by
        New Jersey Revised Statutes 39:5-30.9. This point reduction is available
        to New Jersey drivers once every five years. You are also eligible for a
        discount on your car insurance by completing this course! The discount
        is applicable to New Jersey auto insurance and can reach up to 10% off
        your premiums. Additionally, this New Jersey Mature Defensive Driver
        course could help you satisfy requirements if you were court ordered to
        complete a Defensive Driving Course. When the course has been completed,
        you will immediately receive a certificate of completion in your email,
        and it will also be available for download online through your account.
        You will then submit the certificate to your car insurance company or
        the Court that issued the order for a Defensive Driving Course. All
        completions will be automatically filed with the New Jersey Motor
        Vehicle Commission (MVC).
      </p>
      <div className="ui stackable grid">
        <div className="ten wide column">
          <ul>
            <li>Take the course at your own pace (available 24/7)</li>
            <li>Designed for drivers 55 years and older.</li>
            <li>Automatically saves your progress</li>
            <li>Unlimited Exam retakes for Free</li>
            <li>Guaranteed to pass</li>
            <li>New Jersey MVC Approved Course</li>
            <li>No Hidden Fees!</li>
          </ul>
          <p>
            It is that simple! Upon successfully completing the course, we will
            automatically report your completion to the New Jersey MVC for the 2
            point reduction. Additionally, we will send you a certificate of
            completion by email and available to download from your account,
            which you can submit to your car insurance provider to receive your
            insurance discount or court that ordered the program!
          </p>
        </div>
        <div className="six wide column">
          <img
            src="https://static-content.fedsafe.com/images/stateseals/nj.png"
            className="ui image"
            style={{ margin: '0 auto', maxHeight: '300px' }}
            alt="nj"
          ></img>
        </div>
      </div>
    </>
  );
};

export default SDDANJ;
