import React from 'react';
import CoursePageText from './CoursePageText';
import CoursePageVideo from './CoursePageVideo';
import CoursePageQuiz from './CoursePageQuiz';
import CoursePageChapterquiz from './CoursePageChapterquiz';
import CoursePageMiniquiz from './CoursePageMiniquiz';
import CoursePageQuizReviewTvsca from './CoursePageQuizReviewTvsca';
import CoursePageQuizReviewDdc6Nj from './CoursePageQuizReviewDdc6Nj';
import CoursePageQuizReviewSddaNj from './CoursePageQuizReviewSddaNj';
import CoursePageCourseEvaluationCa from './CoursePageCourseEvaluationCa';
import CoursePageSecurityQuestions from './CoursePageSecurityQuestions';

const CoursePage = ({ courseData, setPageValid, showValidationInfo }) => {
  const coursePageForType = () => {
    switch (courseData.coursePageType) {
      case 'Text':
        return (
          <CoursePageText
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
          />
        );
      case 'Video':
        return (
          <CoursePageVideo
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
          />
        );
      case 'Quiz':
        return (
          <CoursePageQuiz
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'SecurityQuestions':
        return (
          <CoursePageSecurityQuestions
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'Miniquiz':
        return (
          <CoursePageMiniquiz
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'QuizReviewTvsca':
        return (
          <CoursePageQuizReviewTvsca
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'QuizReviewDdc6Nj':
        return (
          <CoursePageQuizReviewDdc6Nj
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'QuizReviewSddaNj':
        return (
          <CoursePageQuizReviewSddaNj
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'CourseEvaluationCa':
        return (
          <CoursePageCourseEvaluationCa
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'Chapterquiz':
        return (
          <CoursePageChapterquiz
            key={courseData.hashLink}
            courseData={courseData}
            setPageValid={setPageValid}
            showValidationInfo={showValidationInfo}
          />
        );
      case 'Scenario':
      case 'TwentyFourHourBreak':
      case 'SubmitScenario':
      case 'SecurityQuestionADI12':
      default:
        return <p>Loading...</p>;
    }
  };

  return coursePageForType(courseData);
};

export default CoursePage;
